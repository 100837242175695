
import React from 'react';
import { TextInput, minValue, NumberInput, ImageInput, ReferenceInput,
    ImageField, AutocompleteInput, RadioButtonGroupInput, Create, SimpleForm, SelectInput, required, useTranslate} 
from 'react-admin';
import BrandReferenceInput from './control/brand-reference-input';
// import { DateInput } from 'react-admin-date-inputs2';
// import { DateInput } from 'react-admin-date-inputs2';
// import DateFnsUtils from '@date-io/date-fns';
// import MomentUtils from 'material-ui-pickers/utils/moment-utils';
// import frLocale from "date-fns/locale/fr";

// DateFnsUtils.prototype.getStartOfMonth = DateFnsUtils.prototype.startOfMonth

// import Strings from '../res/strings';
const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.vehicle_model.create')}</span>;
};

const validateSeats = [required(), minValue(1, 'La valeur doit ne pas être inférieure à 1')];

const CreateVehicleModel = props => {
    const translate = useTranslate();

    return(
    <Create title={<Title/>} {...props}>
        <SimpleForm redirect="list">
             <TextInput source="name" label = {translate('name')} validate={required()}/>
             {/* <BrandReferenceInput label="Marque" source="brand_id" reference="vehicle_brand" validate={required()}/> */}
             <ReferenceInput label="Marque" source="brand_id" perPage={99999}
             sort={{field: 'name', order: 'ASC'}}
            reference="vehicle_brand" validate={required()}> 
                <AutocompleteInput optionText="name"/>       
            </ReferenceInput>

             <ReferenceInput label="Type de véhicule" source="vehicle_type_id" perPage={99999}
             sort={{field: 'name', order: 'ASC'}}
            reference="vehicle_type" validate={required()}> 
                <AutocompleteInput optionText="name"/>       
            </ReferenceInput>
            {/* <NumberInput source="seats" label = "Nombre de places" initialValue={1} step={1} validate={validateSeats}/> */}
            
            <RadioButtonGroupInput source="is_locked" label="Verrouillé?" choices={[
                { id: '0', name: 'Non' },
                { id: '1', name: 'Oui' },
            ]} initialValue="0"/>
        </SimpleForm>
    </Create>
)};

export default CreateVehicleModel;
