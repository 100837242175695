import React from 'react';

const VehicleLogo = props => (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" 
     x="0px" y="0px" width="24px" height="24px"
    viewBox="0 0 24 24" enable-background="new 0 0 24 24"> 
     <image id="image0" width="24" height="24" x="0" y="0"
     href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAQAAABKfvVzAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JQAAgIMAAPn/AACA6QAAdTAAAOpgAAA6mAAAF2+SX8VGAAAAAmJLR0QA/4ePzL8AAAAJcEhZ
cwAADsQAAA7EAZUrDhsAAAAHdElNRQfkBAUGDDl0Yh5wAAAB9UlEQVQ4y7XUS0iUcRQF8N83Tpoj
aNhbtJVRBIKbMoyEkKJahWAbvyBaSNTC6AGtWhRRSESQ0SYXwUeLIMomiCJKqAiCIDMiIkqIHvSw
LMV0xGkxOc6kLlz0393LOZd7zrn8g7TZvdgs8YKW/LpSk5UqlSkw6KM3uqP7MxOqnVShWFyBwLiU
UUM6oiuTkHje/LvuavIpp1OiUVdYG/X8q2GVGrVG7cyDMxRdd0tLWBquyyd0eOaGjml17rfdgAdh
kKthvk0u+WWOMV/FlAuMSCEmpULS3uhdroZvSvHSoAVq9OtVYI6YtMCIXt0ZeK7oIinHJLHILkVi
ypUijWJbwpHoXL6trXZIKJQyoMpzSZ0QIQzUe2BrdHOCUOi273r8NmxcDEss0+Cs9ujvvPCicW3q
MivtscLSKe7Uu6M9Wx1yz2dFQctDSetddWEaQ694os4LG9QaUi6pLW6xE8ac1iAt93QDaX2O+6LX
Mz/81Gx59Dau2nm73ZnhOIcdt9lhr5Vqlsgk3SStSqW1fmeBl1Vb6KBijTZ7atAH3M/k0GOjA8Yk
zM0S6rRiDa45JYGjVk8E14d90sa8yKqY55AAPI8eQ3gElRlCEbpsm7J/hfcoyS75yqMMoQyLphE8
XyZUEJ2ZvKUu/Z5OQ+jXKdCX3wz++6/xB9lHkEbRcI1WAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIw
LTA0LTA1VDA2OjEyOjU3KzAwOjAwd3RWzAAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMC0wNC0wNVQw
NjoxMjo1NyswMDowMAYp7nAAAAAASUVORK5CYII=" />
</svg>
);

export default VehicleLogo;
