import React, { Component } from 'react';
import Slide from './image-slide'
import LeftArrow from '../arrow/left-arrow'
import RightArrow from '../arrow/right-arrow'
import '../../App.css'

export default class ImageSlider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentIndex: 0,
      translateValue: 0,
    }
  }

  prevSlide = () => {
    console.log('Prev slide');
    if(this.state.currentIndex === 0)
    return;
  
    this.setState(prevState => ({
      currentIndex: prevState.currentIndex - 1,
      translateValue: prevState.translateValue + this.slideWidth()
    }))
  }
  
  nextSlide = () => {
    console.log('Nex slide');
    // Exiting the method early if we are at the end of the images array.
    // We also want to reset currentIndex and translateValue, so we return
    // to the first image in the array.
    if(this.state.currentIndex === this.props.images.length - 1) {
      return this.setState({
        currentIndex: 0,
        translateValue: 0
      })
    }
    
    // This will not run if we met the if condition above
    this.setState(prevState => ({
      currentIndex: prevState.currentIndex + 1,
      translateValue: prevState.translateValue + -(this.slideWidth())
    }));
  }
  
  slideWidth = () => {
    return document.querySelector('.slider').clientWidth
  }

  clickOnImage = (image) => {
    console.log('click on image: ' + image);
    this.props.clickOnImage(image);
  }

  render() {
    return (
      // style={{width:400, height:400, backgroundColor:'green'}}
      <div className="slider-container">
      {this.props.images.length > 1 ? <LeftArrow prevSlide={this.prevSlide}/> : ''}
      <div className="slider">
			  <div className="slider-wrapper"
          style={{
            transform: `translateX(${this.state.translateValue}px)`,
            transition: 'transform ease-out 0.45s'
          }}>
          
            {
              this.props.images.map((image, i) => (
                <Slide key={i} image={image} width='1000' height='1000' clickOnImage={this.clickOnImage(image)}/>
              ))

            }
        </div>
       
      </div>
      {this.props.images.length > 1 ? <RightArrow nextSlide={this.nextSlide}/> : ''}
      </div>
    );
  }
}