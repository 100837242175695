
import React from 'react';
import { TabbedForm, FormTab, TextInput, Edit, SimpleForm, SelectInput, required, useTranslate } from 'react-admin';
import CardActions from '@material-ui/core/CardActions';
import { ListButton, Toolbar, SaveButton, DeleteButton } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import { DateInput } from 'react-admin-date-inputs2';
// import MomentUtils from 'material-ui-pickers/utils/moment-utils';
import frLocale from "date-fns/locale/fr";
import {locationTypes} from '../../util/variant'

const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

const CustomToolbarNoStyle = ({permissions, ...props}) => (
    <Toolbar {...props}>
        <SaveButton />
        {permissions === 'Admin' &&
        <DeleteButton label="ra.action.delete" redirect='list' {...props}/>}
    </Toolbar>
);

const CustomToolbar = withStyles(toolbarStyles)(CustomToolbarNoStyle);

const cardActionStyle = {
    zIndex: 2,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    padding: 0,
};

const EditActions = ({ basePath, data, resource }) => (
    <CardActions style={cardActionStyle}>
        <ListButton basePath={basePath}/>
    </CardActions>
);
const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.sms_template.edit')}: {record ? `${record.name}` : ''}</span>;
};

const EditSmsTemplate = ({classes, permissions, ...props })  => (
    <Edit title={<Title />} {...props} actions={<EditActions/>}>

        <SimpleForm toolbar={<CustomToolbar />}>
            <TextInput source="code" label="Code" disabled fullWidth/>
            <TextInput source="name" label="Nom" validate={required()} fullWidth/>
            <TextInput source="content" label="Contenu" validate={required()} fullWidth multiline/>
            
        </SimpleForm>
            
         
    </Edit>
);

export default EditSmsTemplate;