import React, { Component } from 'react'

import '../../App.css'
import '../../util/constant'
// import {Button} from 'react-admin';
// import IconCancel from '@material-ui/icons/Cancel';
// import Dialog from '@material-ui/core/Dialog';
// import DialogTitle from '@material-ui/core/DialogTitle';
// import DialogContent from '@material-ui/core/DialogContent';
// import DialogActions from '@material-ui/core/DialogActions';
import PdfViewer from './pdf-viewer'

// import { PDFReader } from 'react-read-pdf';
import { getUrlExtension } from '../../util/function';



export default class Slide extends Component {
  // constructor(props) {
  //   super(props);

  //   this.state = {
  //     showDialog: false
  //   }
  // }

  // clickOnImage = () => {
  //   this.setState({showDialog: true});
  // }

  // closeDialog = () => {
  //   console.log("closeDialog");
  //   this.setState({showDialog: false});
  // }

  render() {
    const style = {
      backgroundImage: `url(${this.props.file})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 60%',
      
    };

    // const dialogStyle = {
    //   backgroundImage: `url(${this.props.image})`,
    //   backgroundSize: 'cover',
    //   backgroundRepeat: 'no-repeat',
    //   backgroundPosition: '100% 100%',
    //   width: `${this.props.width}`,
    //   height: `${this.props.height}`,
    //   // width: 1000,
    //   // height: 1000,
    // };

    var ext = getUrlExtension(this.props.file);
    if(ext === "pdf")
      return (<div className="slide" style={style}><PdfViewer file={this.props.file}/></div>);
    else
      return (<div className="slide" style={style} onClick={this.props.clickOnFile}>
    
      </div>);
  }
}

