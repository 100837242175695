
import React from 'react';
import { TextInput, validate, minValue, NumberInput, Edit, ImageField, SimpleForm, SelectInput, RadioButtonGroupInput, required, ImageInput, useTranslate } from 'react-admin';
import CardActions from '@material-ui/core/CardActions';
import { ListButton, Toolbar, SaveButton, DeleteButton } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import { DateInput } from 'react-admin-date-inputs2';
// import MomentUtils from 'material-ui-pickers/utils/moment-utils';
import frLocale from "date-fns/locale/fr";
import {locationTypes} from '../../util/variant'

const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

const CustomToolbarNoStyle = ({permissions, ...props}) => (
    <Toolbar {...props}>
        <SaveButton />
        {/* <DeleteButton label="ra.action.delete" redirect='list' {...props}/> */}
    </Toolbar>
);

const CustomToolbar = withStyles(toolbarStyles)(CustomToolbarNoStyle);

const cardActionStyle = {
    zIndex: 2,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    padding: 0,
};

const EditActions = ({ basePath, data, resource }) => (
    <CardActions style={cardActionStyle}>
        <ListButton basePath={basePath}/>
    </CardActions>
);
const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.vehicle_type.edit')}: {record ? `${record.name}` : ''}</span>;
};

const validateMaxSeats = [required(), minValue(1, 'La valeur doit ne pas être inférieure à 1')];

const EditVehicleType = ({classes, permissions, ...props })  => {
    const translate = useTranslate();
    const uploadFileText = translate('ra.input.file.upload_single') + '(' + translate('max_size') + '100KB)';
    return (
        <Edit title={<Title />} {...props} actions={<EditActions/>}>
        <SimpleForm redirect="list" toolbar={<CustomToolbar/>}>
                
            <TextInput source="name" label = "Nom" validate={required()}/>
            <NumberInput source="max_seats" label = "Sièges max" initialValue={1} step={1} 
                validate={validateMaxSeats}/>
            {/* <NumberInput source="max_luggages" label = "Bagagges max" initialValue={0} step={1} 
                validate={[required, minValue(0, 'La valeur doit être supérieure ou égale à 0')]}/> */}
                <NumberInput source="ordering" label = "Ordre d'apparition" initialValue={1} step={1} 
                validate={required()}/>
            <ImageInput source="map_icon" label="Icone sur la carte" accept="image/*" maxSize='100000' placeholder={uploadFileText} validate={required()}>
                <ImageField source="url" title="Fichier" />
            </ImageInput>

            <ImageInput source="select_icon" label="Icone pour la sélection" accept="image/*" maxSize='100000' placeholder={uploadFileText} validate={required()}>
                <ImageField source="url" title="Fichier" />
            </ImageInput>
{/*             
            <RadioButtonGroupInput source="is_locked" label="Vérrouillé?" choices={[
                { id: '0', name: 'Non' },
                { id: '1', name: 'Oui' },
            ]} /> */}

        </SimpleForm>
    </Edit>
)};

export default EditVehicleType;