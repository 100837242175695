import React, {Fragment} from 'react';
import { ReferenceField, List, Datagrid, TextField, DateField, SelectInput, EditButton, Filter, TextInput, useTranslate } from 'react-admin';
import BatchDeleteButton from '../../control/button/batch-delete-button';
import {locationDict, locationTypes} from '../../util/variant'
import {OrderTypeField, OrderTypeFilter, OrderActivityTypeField} from '../../control/field/index'

const BulkActionButtons = props => (
    <Fragment>
        {/* <BatchDeleteButton label="Supprimer" {...props} /> */}
    </Fragment>
);
const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.price.list')}</span>;
};

// const TypeTextField = ({ source, record = {}, label}) => {
//     const locationType = locationDict[record.type];
//     return <span class="MuiTypography-root MuiTypography-body2">{typeof(locationType) !== 'undefined' ? locationType.name : record.type}</span>;
// }
    
// TypeTextField.defaultProps = {
//     addLabel: true,
// };

const ListFilter = ({ permissions, ...props })  => (
    <Filter {...props}>
        {/* <TextInput label="Name" source="name" alwaysOn resettable/>
        <TextInput label="Address" source="address" alwaysOn resettable/>
        <SelectInput label="Type" source="type" alwaysOn choices={locationTypes}
            allowEmpty emptyText="Tous les types" emptyValue='0' initialValue='0'
        /> */}
        
    </Filter>
);

const ListPrice = ({permissions, ...props}) => (
    <List title={<Title/>} {...props} 
        filters = {<ListFilter permissions={permissions} />} 
        bulkActionButtons={<BulkActionButtons />} exporter={false}>
        <Datagrid rowClick="edit">
            <TextField source="vehicle_type_name" label = "Type de véhicule"/>
            {/* <OrderActivityTypeField label="Type d'activité"/> */}
            <OrderTypeField source="order_type" label="Type de commande"/>
            <TextField source="min" label="Minimum(XPF)"/>
            <TextField source="base" label = "Base(XPF)"/>
            <TextField source="per_km" label="XPF/km"/>
            <TextField source="per_minute" label="XPF/minute"/>
            {/* <TextField source="wait" label="Attente de 5 minute(XPF)"/> */}
            {/* <TextField source="markup" label="Supplément(XPF)"/> */}
            {/* <TextField source="quantity_markup" label="Supplément au dela 3 passagers(XPF/passager)"/> */}
        </Datagrid>
    </List>
);

export default ListPrice;

