import React from 'react';
import compose from 'recompose/compose';
import Card from '@material-ui/core/Card';
import CustomerIcon from '@material-ui/icons/Person';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { translate } from 'react-admin';

import CardIcon from '../../control/view/card-icon';

const styles = {
    main: {
        flex: '1',
        marginLeft: '1em',
        marginTop: 20,
    },
    card: {
        overflow: 'inherit',
        textAlign: 'right',
        padding: 16,
        minHeight: 52,
    },
};

const DriverNbDashboard = ({ value, translate, classes }) => (
    <div className={classes.main}>
        <CardIcon Icon={CustomerIcon} bgColor="#fa9e" />
        <Card className={classes.card}>
            <Typography className={classes.title} color="textSecondary">
                <Link href="#/driver">
                    {translate('dashboard.drivers.list')}
                </Link>
                
            </Typography>
            <Typography variant="headline" component="h2">
                {value}
            </Typography>
        </Card>
    </div>
);

const enhance = compose(
    withStyles(styles),
    translate
);

export default enhance(DriverNbDashboard);
