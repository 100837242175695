
import React from 'react';
import {number, AutocompleteInput, Pagination, TextField, ReferenceManyField, Datagrid, FormTab, TabbedForm, ReferenceInput, TextInput, Edit, SimpleForm, SelectInput, required, useTranslate } from 'react-admin';
import CardActions from '@material-ui/core/CardActions';
import { EditButton, ListButton, Toolbar, SaveButton, DeleteButton } from 'react-admin';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import { DateInput } from 'react-admin-date-inputs2';
// import MomentUtils from 'material-ui-pickers/utils/moment-utils';
import frLocale from "date-fns/locale/fr";
import {orderTypes, OrderType} from '../../util/constant'
import { OrderTypeField, ValidateField } from '../../control/field';
import {activityTypes} from '../../util/variant'

const useStyles = makeStyles({
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
    
});
const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

const CustomToolbarNoStyle = ({permissions, ...props}) => (
    <Toolbar {...props}>
        <SaveButton />
        {permissions === 'Admin' &&
        <DeleteButton label="ra.action.delete" redirect='list' {...props}/>}
    </Toolbar>
);

const CustomToolbar = withStyles(toolbarStyles)(CustomToolbarNoStyle);

const cardActionStyle = {
    zIndex: 2,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    padding: 0,
};

const EditActions = ({ basePath, data, resource }) => (
    <CardActions style={cardActionStyle}>
        <ListButton basePath={basePath}/>
    </CardActions>
);

const Title = ({ record }) => {
    var string = "";
    if(record){
        const vehicleTypeName = record ? record.vehicle_type_name : '';
        const orderType = record ? record.order_type : 0;
        if(orderType == OrderType.IMMEDIATE)
            string = vehicleTypeName + " - Immédiate";
        else
            string = vehicleTypeName + " - Réservation à l'avance";
    }
    const translate = useTranslate();
    return <span>{translate('resources.price.show')} pour: {record ? `${string}` : ''}</span>;
    
};

const validatePricePositive = (value, allValues) => {
    if (value <= 0) {
        return 'Doit être plus grand que 0';
    }
    return [];
};

const validatePriceNotNegative = (value, allValues) => {
    if (value < 0) {
        return 'Doit ne pas être moins que 0';
    }
    return [];
};

const validatePrice = [required(), number(), validatePricePositive];
const validatePriceAdditional = [required(), number(), validatePriceNotNegative];


const EditPriceRoute = ({permissions, ...props })  => {
    const classes = useStyles();
    return (
    <Edit title={<Title />} {...props} actions={<EditActions/>}>
         <SimpleForm redirect="list" 
                toolbar={<CustomToolbar/>}>

        {/* <ReferenceInput label="Type de véhicule" source="vehicle_type_id" perPage={99999}
            reference="vehicle_type" validate={required()}>
                <AutocompleteInput optionText="name"/>       
            </ReferenceInput> */}
            {/* <SelectInput source="activity_type" label="Type d'activité" validate={required()} 
                            choices={activityTypes} className={classes.inlineBlock}/> */}
            
            {/* <SelectInput source="order_type" label="Type de commande" validate={required()} 
                choices={orderTypes} className={classes.inlineBlock}/>
            <div/> */}

            {/* <ReferenceInput label="Adresse de départ" source="from_id" perPage={99999} fullWidth
            reference="location" validate={required()}>
                <AutocompleteInput optionText="address"/>       
            </ReferenceInput>

            <ReferenceInput label="Adresse d'arrivée" source="to_id" perPage={99999} fullWidth
            reference="location" validate={required()}>
                <AutocompleteInput optionText="address"/>       
            </ReferenceInput> */}
            <TextField source="vehicle_type_name" label = "Type de véhicule"/>
            {/* <OrderActivityTypeField label="Type d'activité"/> */}
            {/* <OrderTypeField source="order_type" label="Type de commande"/> */}
            <TextField source="from_address" label = "Adresse de départ"/>
            <TextField source="to_address" label = "Adresse d'arrivée"/>

            <TextInput source="per_package" label = "Prix(XPF)" validate={validatePriceAdditional}/>
        </SimpleForm>
    </Edit>
)};

export default EditPriceRoute;