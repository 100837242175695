
import React from 'react';
import { TextInput, minValue, NumberInput, ImageInput, 
    ImageField, RadioButtonGroupInput, Create, SimpleForm, SelectInput, required, useTranslate} 
from 'react-admin';
// import { DateInput } from 'react-admin-date-inputs2';
// import { DateInput } from 'react-admin-date-inputs2';
// import DateFnsUtils from '@date-io/date-fns';
// import MomentUtils from 'material-ui-pickers/utils/moment-utils';
// import frLocale from "date-fns/locale/fr";

// DateFnsUtils.prototype.getStartOfMonth = DateFnsUtils.prototype.startOfMonth

// import Strings from '../res/strings';
const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.vehicle_type.create')}</span>;
};

const validateMaxSeats = [required(), minValue(1, 'La valeur doit ne pas être inférieure à 1')];
// const validateMaxLuggages = [required(), minValue(0, 'La valeur doit ne pas être inférieure à 0')];

const CreateVehicleType = props => {
    const translate = useTranslate();
    const uploadFileText = translate('ra.input.file.upload_single') + '(' + translate('max_size') + '100KB)';

    return(
    <Create title={<Title/>} {...props}>
        <SimpleForm redirect="list">
             <TextInput source="name" label = {translate('name')} validate={required()}/>
            <NumberInput source="max_seats" label = "Sièges max" initialValue={1} step={1} 
                validate={validateMaxSeats}/>

            {/* <NumberInput source="max_luggages" label = "Bagagges max" initialValue={0} step={1} 
                validate={validateMaxLuggages}/> */}
            <NumberInput source="ordering" label = "Ordre d'apparition" initialValue={1} step={1} 
                validate={required()}/>
            

            <ImageInput source="map_icon" label="Icone sur la carte" accept="image/*" maxSize='100000' placeholder={uploadFileText} validate={required()}>
                <ImageField source="url" title="Fichier" />
            </ImageInput>

            <ImageInput source="select_icon" label="Icone pour la sélection" accept="image/*" maxSize='100000' placeholder={uploadFileText} validate={required()}>
                <ImageField source="url" title="Fichier" />
            </ImageInput>

          
            <RadioButtonGroupInput source="is_locked" label="Verrouillé?" choices={[
                { id: '0', name: 'Non' },
                { id: '1', name: 'Oui' },
            ]} initialValue="0"/>
        </SimpleForm>
    </Create>
)};

export default CreateVehicleType;
