import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/icons/FiberManualRecord';

// import ActivateIcon from '@material-ui/icons/Check';
// import NotActivateIcon from '@material-ui/icons/Clear';
import { TRUE, FALSE } from '../../util/constant';

const useStyles = makeStyles({
     verified: {
        color: 'green',
        
      },
      unverified: {
        color: 'orange',
      },

      parent:{
        // margin:'auto',
        // display: 'flex',
        // alignItems: 'center',
        // justifyContent: 'center',
      },
});

const VerifyField = ({ source, record = {}, label}) => {
    const classes = useStyles();
    return <div className={classes.parent}>
        {record[source] == TRUE ? <Icon className={classes.verified}/> : <Icon className={classes.unverified}/>}
    </div>;
    
};
    // <div>
    //     <span class>{record[source]}</span>
    //     <img src={record[source] == TRUE ? ActivateIcon : NotActivateIcon} 
    //         width='20' height='20'
    //         alt={''}/>
    // </div>;

    VerifyField.defaultProps = {
    addLabel: true,
};

export default VerifyField;