// in PostReferenceInput.js
import React, { Fragment  } from 'react';
import { ReferenceInput, SelectInput, AutocompleteInput } from 'react-admin';
import BrandCreateButton from './brand-create-button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
});

const BrandReferenceInput = (props) => {
    const classes = useStyles();
    return(
    <Fragment>
    {/* <AutocompleteInput optionText="name" /> */}
        <ReferenceInput {...props}  perPage={99999} className={classes.inlineBlock}>
            <AutocompleteInput optionText="name"/>        
        </ReferenceInput>
        <BrandCreateButton className={classes.inlineBlock}/>
    </Fragment>
)};

export default BrandReferenceInput;