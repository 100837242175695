import React from 'react';
import PDFViewer from 'pdf-viewer-reactjs';
import { withRouter } from 'react-router-dom'
import {BACKEND_FILE_URL} from '../../util/variant'
import BackButton from '../button/back-button'

const cardActionStyle = {
  zIndex: 2,
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  flexWrap: 'wrap',
  padding: 0,
};

class PdfShow extends React.Component {
    constructor(props) {
        super(props);
    
        this.state = {
          fileUrl: '',
          showBackButton: false,
        }
      }

      componentWillMount(){
        console.log("PdfShow props:");
        console.log(this.props);
        var url;
        if(typeof(this.props.file) !== 'undefined' && this.props.file != null){
          url = this.props.file.url;
          this.setState({fileUrl: url, showBackButton: false});
        }
        else{
          console.log("PdfShow url:");
          console.log(this.props.match.params.url);

          url = this.props.match.params.url;
          url = url.replace('|', '/');
          url = url.replace('|', '/');
          url = BACKEND_FILE_URL + url;
          this.setState({fileUrl: url, showBackButton: true});
        }
        
        console.log("PdfShow full url: " + this.state.fileUrl);
    }

    render() {
        console.log('State url: ' + this.state.fileUrl);
        return (
          <div>
            {this.state.showBackButton ? <div style={cardActionStyle}><BackButton/></div> : ''}
            <PDFViewer document={{url: this.state.fileUrl}} navbarOnTop={true} loader='Téléchargement en cours...'/>
          </div>
          
        );
    }
}

export default withRouter(PdfShow);