import React from 'react';
import '../../App.css'

const RightArrow = (props) => {
  return (
    <div className="nextArrow arrow" onClick={props.nextSlide}>
      {/* <i className="fa fa-arrow-right fa-2x" aria-hidden="true"></i> */}
      <i className="arrow-right"></i>
    </div>
  );
}

export default RightArrow;

 