import React from 'react';
import PropTypes from 'prop-types';

import LockIcon from '@material-ui/icons/Lock';
import UnlockIcon from '@material-ui/icons/LockOpen';

import { TRUE, FALSE } from '../../util/constant';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles({
     parent:{
      //  margin:'auto',
      //  display: 'flex',
      //  alignItems: 'center',
      //  justifyContent: 'center',
     },
});

const LockField = ({ source, record = {}, label}) => {
    const classes = useStyles();
    return <div className={classes.parent}>
    {record[source] == TRUE ? <LockIcon color='primary'/> : <UnlockIcon color='disabled'/>}
    </div>;
};

LockField.defaultProps = {
    addLabel: true,
};

export default LockField;