import React from 'react';
// import PDFViewer from 'pdf-viewer-reactjs';
import { withRouter } from 'react-router-dom'
import { showNotification, Confirm, crudGetOne, Button, Loading } from 'react-admin';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
// import FileSlider from './file-slider'
import IconCancel from '@material-ui/icons/Cancel';
import {BACKEND_FILE_URL} from '../../util/variant'
// import BackButton from '../back-button'
import '../../App.css'

const cardActionStyle = {
  zIndex: 2,
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  flexWrap: 'wrap',
  padding: 0,
};

const styles = {
  flex: { display: 'flex', marginLeft:'50'},
  // flexColumn: { display: 'flex', flexDirection: 'column', marginLeft:'3em' },
  flexColumn: { display: 'flex', flexDirection: 'column', marginLeft:'0em', marginRight:'1em' },
  leftCol: { flex: 1, marginRight: '1em' },
  rightCol: { flex: 1, marginLeft: '1em' },
  singleCol: { marginTop: '2em', marginBottom: '2em' },
  map:{ flex: 1, marginTop: '1em', marginBottom: '1em' },
  button:{ flex: 1, marginTop: '1em'},
};

class ImageViewer extends React.Component {
    constructor(props) {
        super(props);
    
        this.state = {
          files:[],
          fileUrl: '',
          title: '',
          // titleHeight:0,
          hasTitle:false,
          width: 200,
          height: 200,
          showDialog: false,
          // showBackButton: false,
        }
      }

      onClick(){
        // alert('Clocked');
        if(this.state.fileUrl != '')
          this.setState({showDialog: true});
        else
          alert('Aucune photo à afficher');
      }

      onClose(){
        // alert('Clocked');
        this.setState({showDialog: false});
      }

      componentWillMount(){
        console.log("ImageShow props:");
        console.log(this.props);
        var url;
        const {fileUrl, title, width, height, files} = this.props;
        this.setState({files: files});

        if(typeof(title) !== 'undefined' && title != null){
          this.setState({title: title});
          if(title != '')
            this.setState({hasTitle: true});
        }

        if(typeof(fileUrl) !== 'undefined' && fileUrl != null){
          this.setState({fileUrl: fileUrl});
        }
        else{
          console.log("ImageShow url:");
          console.log(this.props.match.params.url);

          url = this.props.match.params.url;
          url = url.replace('|', '/');
          url = url.replace('|', '/');
          url = BACKEND_FILE_URL + url;
          this.setState({fileUrl: url});
        }
        console.log("ImageShow full url: " + this.state.fileUrl);
        
    }

    render() {
        console.log('State url: ' + this.state.fileUrl);
        var {round} = this.props;
        if(round === 'undefined')
          round = '0%';

        return (
          <div>
            <div style={{ display: 'flex', flexDirection: 'column', marginLeft:'0em', marginRight:'1em', width: this.state.width}}>
              {/* <div style={cardActionStyle}><BackButton/></div> */}
              {/* <div class="image-container"> */}
                {/* {this.state.hasTitle && <span style={{marginLeft:20, height:50}}>{this.state.title}</span>}
                <img src={this.state.fileUrl} alt='' style={{width: this.state.width, height:this.state.height, marginLeft:20}} onClick={() => this.onClick()}/> */}
                
                <img src={this.state.fileUrl} alt='' style={{width: this.state.width, height:this.state.height, marginLeft:0, borderRadius:round}} onClick={() => this.onClick()}/>
                {this.state.hasTitle && <span style={{marginLeft:0, marginRight:0, height:'auto'}}>{this.state.title}</span>}
              {/* </div> */}
            </div>
            
            <Dialog fullWidth={true}
                      maxWidth="1000xs"
                      open={this.state.showDialog}
                      
                      aria-label="Previsualisation">

              <DialogTitle></DialogTitle>
            
              <DialogContent>
                <div style={{width:'auto', height:'100%', border:'1px solid white'}}>
                        {/* {this.state.files.length > 0 ? <FileSlider files={this.state.files}/> : ''} */}
                        <img src={this.state.fileUrl} alt='' 
                          style={{width: '80%', height:'auto', 
                                maxWidth:'2000px',
                              maxHeight:'2000px',
                          }}/>
                </div>
              </DialogContent>
              <DialogActions>
                  <Button label="Fermer" onClick={() => this.onClose()}>
                      <IconCancel />
                  </Button>
              </DialogActions>
            </Dialog>
        </div>
        );
    }
}

export default withRouter(ImageViewer);