
import React from 'react';
import { TextInput, Create, ImageInput, ImageField, SimpleForm, required, useTranslate} from 'react-admin';
// import { DateInput } from 'react-admin-date-inputs2';
import { DateInput } from 'react-admin-date-inputs2';
import DateFnsUtils from '@date-io/date-fns';
// import MomentUtils from 'material-ui-pickers/utils/moment-utils';
import frLocale from "date-fns/locale/fr";

DateFnsUtils.prototype.getStartOfMonth = DateFnsUtils.prototype.startOfMonth

// import Strings from '../res/strings';
const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.client_pro.create')}</span>;
};

const CreateClient = props => {
    const translate = useTranslate();
    const uploadFileText = translate('ra.input.file.upload_single') + '(' + translate('max_size') + '100KB)';
    return (
    <Create title={<Title/>} {...props}>
        <SimpleForm redirect="list">
            <TextInput source="email" label="Email" validate={required()}/>
            <TextInput source="mobile" label="Mobile" validate={required()}/>
            <TextInput source="last_name" label="Nom" validate={required()}/>
            <TextInput source="first_name" label="Prénom" validate={required()}/>
            <ImageInput source="photo" label="Photo" accept="image/*" maxSize='100000' placeholder={uploadFileText}>
                <ImageField source="url" title="Fichier" />
            </ImageInput>

            {/* <DateInput source="birth_day" label="Date de naissance"
                 providerOptions={{ utils: DateFnsUtils, locale: frLocale }}
            /> */}
            {/* <DateInput source="birth_day" label="Date de naissance"
            autoComplete="" options={{ format: 'dd/MM/yyyy' }} 
                 providerOptions={{ utils: DateFnsUtils, locale: frLocale }}
            /> */}
            
        </SimpleForm>
    </Create>
)};

export default CreateClient;

{/* options={{ format: 'dd/MM/yyyy' }  */}