import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { showNotification, Confirm, crudGetOne, Button, Loading } from 'react-admin';

import DownloadIcon from '@material-ui/icons/Attachment';
// import axios from 'axios';
import {BACKEND_FILE_URL} from '../../util/variant'

class DownloadButton extends Component {
    state = {
        isLoading: false,
    }

    handleClick = () => {
        const link = document.createElement('a');
        link.href = BACKEND_FILE_URL + this.props.fileUrl;
        link.setAttribute('download', this.props.fileName);
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        link.click();
        this.setState({isLoading: false});
    }

    

    render() {
        return (
            <Fragment>
                <Button label="" onClick={this.handleClick} disabled={false}> 
                    <DownloadIcon/>
                </Button>
                { this.state.isLoading && 
                    <Loading loadingPrimary="" loadingSecondary="" />
                }
            </Fragment>
        );
    }
}

export default connect(undefined, { crudGetOne })(DownloadButton);

// HandleButton.propTypes = {
//     push: PropTypes.func,
//     record: PropTypes.object,
//     showNotification: PropTypes.func,
// };

// export default connect(null, {
//     showNotification,
//     push,
// })(UnhandleButton);