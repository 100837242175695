import React from 'react';

import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/icons/FiberManualRecord';

// import ActivateIcon from '@material-ui/icons/Check';
// import NotActivateIcon from '@material-ui/icons/Clear';
import { DRIVER_STATUS_FREE, DRIVER_STATUS_BUSY, DRIVER_STATUS_OFFLINE } from '../../util/constant';

const useStyles = makeStyles({
     free: {
        color: 'green',
      },
      busy: {
        color: 'red',
      },
      offline: {
        color: 'gray',
      },

      parent:{
        margin:'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
});

const DriverStatusField = ({ source, record = {}, label}) => {
    const classes = useStyles();
    var statusText;
    var statusIcon;
    if(record[source] === DRIVER_STATUS_FREE){
      statusText = <span>En ligne</span>;
      statusIcon = <Icon className={classes.free}/>;
    }
    else
      if(record[source] === DRIVER_STATUS_BUSY){
        statusText = <span>En course</span>;
        statusIcon = <Icon className={classes.busy}/>;
      }
      else{
        statusText = <span>Hors ligne</span>;
        statusIcon = <Icon className={classes.offline}/>;
      }

    return <div>{statusText}{statusIcon}
    </div>;
    
};
    // <div>
    //     <span class>{record[source]}</span>
    //     <img src={record[source] == TRUE ? ActivateIcon : NotActivateIcon} 
    //         width='20' height='20'
    //         alt={''}/>
    // </div>;

    DriverStatusField.defaultProps = {
    addLabel: true,
};

export default DriverStatusField;