
import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { Button, Confirm, crudDeleteMany } from 'react-admin';
import ActionDelete from '@material-ui/icons/Delete';

class BatchDeleteButton extends Component {
    state = {
        isOpen: false,
    }

    handleClick = () => {
        this.setState({ isOpen: true });
    }

    handleDialogClose = () => {
        this.setState({ isOpen: false });
    };

    handleConfirm = () => {
        const { basePath, crudDeleteMany, resource, selectedIds } = this.props;
        crudDeleteMany(resource, selectedIds, {}, basePath);
        this.setState({ isOpen: true });
    };

    render() {
        return (
            <Fragment>
                <Button label="ra.action.delete" onClick={this.handleClick}>
                    <ActionDelete />
                </Button>
                <Confirm
                    isOpen={this.state.isOpen}
                    title="Suppression"
                    content={this.props.content}
                    onConfirm={this.handleConfirm}
                    onClose={this.handleDialogClose}
                />
            </Fragment>
        );
    }
}

export default connect(undefined, { crudDeleteMany })(BatchDeleteButton);