import React from 'react';
// import { Container, Header, Grid, Form } from 'semantic-ui-react';
// import { Document, Page } from 'react-pdf';
import PDFViewer from 'pdf-viewer-reactjs';

// const divStyle = {
//   // width: '40px',
//   // height: '80px',
//   // border: '5px solid pink'


// };

export default class PdfViewer extends React.Component {

  // state = {
  //   numPages: 0,
  //   pageNumber: 1,
    
  // }

  
  // onDocumentLoadSuccess = ({ numPages }) => {
  //   this.setState({ numPages });
  // }

  // prevPage = () => {
  //   const currentPageNumber = this.state.pageNumber;
  //   let prevPageNumber;

  //   if (prevPageNumber - 1 <= 0) {
  //     prevPageNumber = 1;
  //   } else {
  //     prevPageNumber = currentPageNumber - 1;
  //   }

  //   this.setState({
  //     pageNumber: prevPageNumber
  //   });
  // }
    
  // nextPage = () => {

  //   const currentPageNumber = this.state.pageNumber;
  //   let nextPageNumber;

  //   if (currentPageNumber + 1 > this.state.numPages) {
  //     nextPageNumber = 1;
  //   } else {
  //     nextPageNumber = currentPageNumber + 1;
  //   }

  //   this.setState({
  //     pageNumber: nextPageNumber
  //   });
  // }

  render() {
    // const { pageNumber, numPages } = this.state;

    return (
      <PDFViewer document={{url: this.props.file}} navbarOnTop={true} loader='Téléchargement en cours...'/>

      // <div>
      //   <nav>
      //     <button onClick={this.goToPrevPage}>Prev</button>
      //     <button onClick={this.goToNextPage}>Next</button>
      //   </nav>

      //   <div style={{ width: 1000 }}>
      //     <Document
      //       file={this.props.file} 
      //       onLoadSuccess={this.onDocumentLoadSuccess}
      //     >
      //       <Page pageNumber={pageNumber} width={1000} />
      //     </Document>
      //   </div>

      //   <p>
      //     Page {pageNumber}/{numPages}
      //   </p>
      // </div>
    
      // <Container>
      //   <br />
      //   {/* <Header textAlign="center">PDF Preview</Header> */}
      //   <Grid centered columns={2} >
      //     <Grid.Column textAlign="center" onClick={this.nextPage}>
      //     <div  style={divStyle}>
      //       <Document file={this.props.file} onLoadSuccess={this.onDocumentLoadSuccess} noData={<h4>Aucun fichier PDF trouvé</h4>}>
      //         <div  style={divStyle}>
      //           <Page pageNumber={pageNumber}/>
      //         </div>
              
      //       </Document>
      //     </div>
      //       {this.props.file ? <p>Page {pageNumber}/{numPages}</p> : null}
      //     </Grid.Column>
      //   </Grid>
      // </Container>
    );
  }
}

