
import React from 'react';
import { TextInput, Create, SimpleForm, SelectInput, required, number, useTranslate} from 'react-admin';
import Autocomplete from 'react-google-autocomplete';
import axios from 'axios';

// import { DateInput } from 'react-admin-date-inputs2';
// import { DateInput } from 'react-admin-date-inputs2';
// import DateFnsUtils from '@date-io/date-fns';
// import MomentUtils from 'material-ui-pickers/utils/moment-utils';
// import frLocale from "date-fns/locale/fr";

// DateFnsUtils.prototype.getStartOfMonth = DateFnsUtils.prototype.startOfMonth
import {locationTypes, GOOGLE_API_KEY} from '../../util/variant'



// import Strings from '../res/strings';
const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.sms_template.create')}</span>;
};

const validateLatVal = (value, allValues) => {
  if (value <= -90 || value > 90) {
      return 'Doit être en [-90, 90]';
  }
  return [];
};

const validateLat = [required(), number(), validateLatVal];

const validateLngVal = (value, allValues) => {
  if (value < -180 || value > 180) {
      return 'Doit être en [-180, 180]';
  }
  return [];
};

const validateLng = [required(), number(), validateLngVal];

const CreateEmailTemplate = props => {
    
    return(
    <Create title={<Title/>} {...props}>
        <SimpleForm redirect="list">
            <TextInput source="code" label="Code" validate={required()} fullWidth/>
            <TextInput source="name" label="Nom" validate={required()} fullWidth/>
            <TextInput source="content" label="Contenu" validate={required()} fullWidth multiline/>
        </SimpleForm>
    </Create>
)};

export default CreateEmailTemplate;
