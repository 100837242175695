import React from 'react';
import { Field } from 'redux-form';
import { Labeled } from 'react-admin';

const EmailInputField = () => (
    <Labeled label="Test">
        <span>
            <Field name="lat" component="input" type="email" placeholder="Email" autoComplete="new-password"/>
        </span>
    </Labeled>
);
export default EmailInputField;