import React from 'react';

const Logo = props => (
  <svg version="1.1" id="Layer_1" 
  xmlns="http://www.w3.org/2000/svg" 
  
   x="0px" y="0px" width="194px" height="50px" 
   viewBox="0 0 194 50" enable-background="new 0 0 194 50" 
   
   >  <image id="image0" width="194" height="50" x="0" y="0"
    href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMIAAAAyCAYAAAAOcwQoAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JQAAgIMAAPn/AACA6QAAdTAAAOpgAAA6mAAAF2+SX8VGAAAABmJLR0QA/wD/AP+gvaeTAAAA
CXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH5QsMERkxYGHOXQAAKoVJREFUeNrtnXd0XcXV9n9z
zrm9qTfLli3Jcu8FF2zTu+klJARIgBBCSCAkhBQCIQQIBAjhDYQXQugldIPpLriBjavcZVuSZVm9
3X7vafP9cWVjwNQQlPdbetaS16zjmbl77tnPzN579swV9KMf/0X49XU3sWNXHQG/373yg7Vn2FL+
wOnQygTogATE/soCdFOQ0nsfCQkSFAFel0QRn+heAqplS8UwzdUDBxTfM/+Fx5Y73R5bfCHp+tGP
bwg33XInv/3Vzzhu7jknb9i45e9Op6NYVZSD1jUtQW7IorLAxLIFElAQGBZsb1ZJ6QL1Y02llCiq
Qjpt4HY5l54897hfZGWFNqt9PfB+9ONA5JeU8a1zz3Otr970o1gsdoTP60FRVFT1k3+WrTKkUHLE
GJOSbEFJjqCsAPJDCnVtTtKmisPxyXaKEDidDnTdCMXjCbuxsblG6+uB96MfByISjbFh42aXLWWO
YkO0rZuD2TiQsZHWbRKsWAtg7X+uCPA4kygC0gdrKDMWljvkV2KJRI6STLn6idCP/yoY6TQpKUlG
orJk1FCGzZyMric/vYH8jM7EwesrqoK0YeuCFdKOpWzF5ZT9ROjHfxWkaWHZukxF43LE2OEc8+Pv
E4m1c3Ct7sWnKPxB+0fi0JzYhmTvlh20bdghhRD0E6Ef/10QIBSBoggsSyee7iHeFflMHnwpSInm
dCAB2zQRikAIgfJvd9yPfnyN+Ii+y8wM/nVCAvIgrOonQj/+yyG+vtXggC4/VugnQj/6Af1E6Ec/
gH4i9OP/OqQEKTMOtqpkjB355f2K/4qo0Qln/xbbhpVbutETYRJtLRmHRhzEqdGTDCorZ/Jh4xHC
4tkHft7X4vejjyCljdPtRPP6SaYl6ZSNx6/i1Gz0aAzTtBDiizkYfU6EGSfeSFx34wuUiFhybZaR
cjhJqoAQByGCtG1VTaYdKVfO6O4sZ1Re9evHuevm8/p6GP34JiEz//iygnR0WWx+fS2O7h14ZDdR
UYJn8AhGz6rA5UyTTqS+EBn6nAgqabqNSULteGzOqaN3XFZaYIbiCUOCPKj0ijBdrT09HTve129d
teTRNX/88/y+HkI/vnFIvFkBtld3suPJ/+WEoQsZmx/Bi0WXrbF0QzELVp3FtIvPJBRwoSfTn0uG
PiXCEWfejaZaOJX23JzU9iuv+v7WU0YNMpH6pwgtgYBk5UaNBx93Jib+eslPd9Zv6Zlz+l9594Wf
9OVQ+vENwul1U7+tncbHbuWqU98h3OPj1ZopRCmmQN3GYdM2M7jur7x4X4TZV16MpqrYtn2Qnj7c
UehTIui6jd9VRyLdXTplTFf56KEpAikFPpITu8/x6RVZl0wcaTJqePfI+SsXF2T72nqaeyr7chj9
+AYhFEHacrBr/itcOOddNm4rYV7r5Uz81tEU53lo2NLOrS89yhVHP81h3c+zefEkJhw3ET2e+ITP
KaHXAv8GUyxO/+61GOpQHLKFlrYwLe3d6KkG3p53DzOOmTu5pChRrkiFWEwB5aN7HpkgQG++uZQ4
c2wGFPSU57mqx7w+77maCy6/m+PmPMP7S19h1eLH+/pd9eM/CEXTaNrVxFjXEnxC4c3W8zj5V9/C
LSLo8RhTZxWSU3Q5zz/Rwvcmv0P19hXEE+NwaSq29dFVQQghUqm0aoP4jxBh+PSLqN+zm2mTDiHS
3U1Z9npG5DzM1HKNWxb8zmXoiTzDVP3JtHROO/NWV7z17Vk1W52+l+LFJJIKQoBDzSxblg1m7/mh
fc81h01trZ1jSPvwOWc/vvS19VUdD51+sh0a6SIvcCJ7e0qIJ3V2Hv0wFQuvZtfKO/v6/fXj64AE
RdWItnUyLLuD2uYciqdMxq8l6WmPoqgqkfYuBgzOobFoKnFjGfn2LqLhNJ585yeJgEBPp1Ub++sl
Qt7Iy+joNPH7/AwrLWVR5I+INU+7rfGxkrisGrF5d+eIqSUPjAv64gNcDsOb7dZdQdVyuLTUwMGV
cfSUii1B2gKJRCgSFAfSUJESHF4LyzBxapIhpZLi6g/ObI5vqRwRyN716MoZa1OmZ9vmpuCO3Wt+
1nXdz043F675BfWpNEWjL6Vl0/19/Rr78XVgn6lgqyBtEDb2Abth+5xigQSpgRS9tsSndCf4erNP
h8+8mpShMLE8ToMxzhGNvD/46OCxMy4+856Zw8raZxeXdhZX+BKuoNN0hVVJ0tJIxlxYUTeJRID3
NuViWCqGrWLaKqZUSJsa2c4wF51ai2kK7n+mEsPtx++IE3QYFBXECwf6m48N2fUku9cn2oxAfGZ5
wZaGMauWLt5UsaI7XrR274ar28bN/q1sKf4n5UPqqF1xY1+/yn58VQiQpkmgMIe9mwqYPXgHL65d
RfSw8XizAhjJNO6Aj7qdXbjb1uAugA6tguEhF7ZlfUa//yYRRh91E4m0QlfjZn46/R4ue+I3voDS
Ma48+MDcyce0HT5xQvfYstIej9Jjs3WPj3ebBtIUq6JH5mC6ctBChbhycnEVZaG6PAhNRdFUFFVD
KApCCbDiuaeZ3fAXqgYZ1HUXwbG/prBE0BSJUt3TQ7K5ATXSSJbS6c2jzjvIUzdnxJj0nKPHN7Wt
adj6Qa5rwett4TGv0nxhQ1Q7Q6pDf40VbYeWB/r6tfbjK8AyTQqHFLLGnsYMUc8xWa/xyp0Bxpx6
GMEcD83V9TS++jxXzKimuj4XtfwQvF4VI64fdIN2H74SEWaffT/bd9YhtQDe5pepbTjUf/trx0w5
bfIbZ8wc13jSrIldpYOHJdRdW1WefWEwNWI2atkh5Iwppag8nxKfC03TUISNkBZI66PLl5S9ppCP
lsqBtLb6mD4qzMTyHawzw1SOH0u8sxuhlmGLyZg2pJM6HU1dbNvViL1zBaNYUHDYxPoT50zde/hr
7+3+doFn2/1L11W8dums1zserT0KV+7V9Gy+o6/faz++JKQtcbkkpUefwCOPbeJX52+nuPpxVjy1
iFZHNvn2Xr53TCedUcHiyNHMmDMRK/XZJIAvQISCcVeBMEE6sU0Dl2hHaXmeI4fEWdh6mSfgqphy
0lHzzzlqeuvJs6d2lI4cmEZzSZ58MZeHqs+h8uTTmDKphKBfYOkGejKJbcbBAOtjt3N8dMQSqSTx
5OfTWZsDIsyIAe0s2rya+JEjMXUDpAkiczbVqwqCQ3MYOrqYaGIK21afwbK3XmR6wTzvecdvmzFp
VPPoAUV7Tl5YM+q+ZCJveVXRohSDLyeg7GJP7Rt9/X778SVgJNOUjx5A04wLeOiJW7nxapu541tI
hPcQKnGzO6pw5ysjqLr0LDxO0BPWFyfCoafcgKYJdu42UEhjGgkMPUpxKEyWO83lw5/g7L9KBe5w
hAfuLCiOdI+ZPfihYycM23nC4TPaK8YNTQq3oYAu2FMHr+w8mkOvuZJB+TqxSIRIWybvQ3zkIPZn
Hb8TYNu4fT66kl6MNIysSOH/YB0dbedQmO0ilUgjEL15V5J0PEkqmkDTFGYeXkZy6vUsevsk1s17
hIuOeSN47VVbz3jntY7xb63c+/T63eVP9NT/zzaGXCInH3s/XZ17qV3d7z/8X4CUEj0a45C5k3l1
41yef+pfXHh6Dh6hkLTS/O0xDdcR5zG4Mp9kJPK5JIBeIhSP+haGodNWu5zG9E+dXm2H39TDmp6U
msenuoWthG5beeLAww47fkhRTrhicHF0ckVx1/ApEzpDFYN0xS+AHhVswC0xTHAG/ORmKSSjcfS0
idJLAGkfYAJ9jnzStnCFgrQmg8TSkFcAQzzb2VvTxIA5AyGe+sgghRCggOpw0NTWwaZ3XiHPkjTK
UdxybRvnX7md7128t2LK2K5rXnir8biloRMeWLt75Mur3/xxS/Gk3zNo2p9oeP+Xff2e+/E5EEJg
mhZuM8msy87lkRvqGLJmA4dOz+GRJ3rYlHcuJx43iXQsnMnU+QJ5d9rkw6+lqXEL0pIhv89/wull
t08M+uxip2b6fC7TU5BlBHOykjmBUCIn6LWCpXkpV+mgONluiWIqEFfAJJPQrQCmoHCATXFyGYue
XM9h544jGNKRtp0hQS8PpMwcmoMDySE/zKCVEqEqBAs91KsDiEQhOyA4pLKZB7esx5xTiebUkDYf
DrT3hgLD5WTTA09x6N0PMs1Q8YS8tJpBnr2tlPI8nSMndzmqyndNmrqsp+r1pc0nLKs57am2nsSC
xK5zO+EamRFPsOcvcN180LMOoTuRTVuPoCseQHEGcbmDZIUClJfl0B3WGZCv8MBdV/e1jnwlPPbE
s3z3O2cxcdqRJJNJxo0bw/q1G7Asi/ETx7Nh4yba2zs5/9vnsOjdJXR29VBSXEQoFGLL1q1UVgxB
Ssm2rduZMGEcdfUN9IQjFBcXcdwxR/Dc8y8jgaOPPJyV7y0lFosy58gTePvNt5BSMm7cWBr3NtHZ
2UnKMHAoKp96ilhkdliFEKTiSYryc6g49wIefe4mUrEWXtl7CDN/eSqKmUA37Y9ZIJ8OzeV04ap6
SClx/uCcI6etuvXwQ2MhB1JxKhK3Bm7NwuU0cbrJ3BpmCkgLiCiZq2QEHz3VYIHPpfCTs2r4n6ev
Yckdx+MpH4PTH8Dp9yFUBWyJoqm9B6dB1XpzKgSoWsZaUxSB5tRISxet+gDC7R4oTlJZmUZfvZG9
9WeQHXKjGwb7GyNxuFw0t6Ywl2/j5LRGWcAPJoyWUTa2Oti5y8thQ3soUuCcYzsCU8eHT17+ftOs
1TWb1+0e8f6qnphzr9PpSs86bG4kEAhEpVtE25qiYUsJJupajFQkmqvjzjfxDjSIXZQIJKdYzYkK
1q1TmHTUz5k4shDdMHjkvl/3tX5/YXz3O2cBgmAo4EgmEz7LtBISGSIzM3UDQcBl23YnEjfgA8KA
IqUMSGQ4M7GJbClFFCnTUspsVVF0IURUSrKkkFJCRGT60qSUXSC9gEcI0Qk4gYBl25H8gkLHlk3b
NURmYnO4XEhpY5oWmsMBSCzDQNNcpBMJJh1WxZrWb3Pj408x7XfnUpDnJNYV/sIkANDaOlqI9NwY
ypnVdPxxp3RmV/olRMU+vcqYO5YK0d4WB/q3ByOtANKCgSHJDZfVsm3r39nW4CUa9ZI0vRimgpQS
Vcuw3jQFiqpiS4FpClRNw7TBtFRUVSGpq4yWKbwuFdKCihI4bvB7rHr4V6Sz7U8kUwkhsBXQBnTy
ZNkAxkYlqippsxR2jYWLx/SgGhkZ1bSgMmQy5KT27BOiXUfU1Tcc1hl3GGlTWJYUhmW5zERSmKbh
NMy0I9EdE93tEVdPQnfEdUvr0o1Hd3fFC2rNqGg1TaUnqTv2Pr3h+613HztW5l1SzpLNZYT1QQwZ
Ukr1thaau5wU50qaq//e17r/EYwaPZ0ZZYPEgp07j3W63aP9ft8LilAuspG2UJQ7hOTbwCSX23Uj
gvHAiULwT4EMKYpytkD5h6JiIOVPFSFfUBR1rUPTrkymkrssy/qXoiiXg0wrgoclXCCEKFZV5RaB
OEoKZtvS/oMQlAPngHjc4XGmItHwIQJB287dLHvwOfLLB1I5exIbXlqIy+9lxDEz2DT/XfR4imkX
nEwiEib3iKOpHF9JIhxBfMmDzlp2KI7XEXfV1mkFby/Mp+CILoK6nSHAPsX+uKJ/HgSQEnhUlQkj
JBNGxbHtGLYlM6ZMb5X91tF+c4nemwtgn37bNqAJ3IoCSQVNge8fv4fTw7tRpPykPDKzcpknOVm7
K0RboxNFkSgaXDQ6ypj8RGZF29cumSFEvmaTPyKmoOLqvQgNaUlsCVhgmZCyVOKGStIQpEwhE3GH
He5yJxraXeGOmBbpia9v2rln8dYH3xy/Mzu3pHp7c3xzpG5a9Nysy1LdobMYXDKIDzY1k111Id01
D/eV3n8CBcWFNKmKWlRY6LKkvc3n87YKxHwQthAiDiwVUm53uhydQohqkFEhqBUClxA8hRC1qiIs
AY8JwQ6hiA5VVZ5Pp/WwtO2woojXJMIUQnRJeEsgfIoiuhFiJVLuldLuEGCA+JeU7LRNQ3O53HXA
4GBBLuNOOQJvdhBfbhbDj5qOoih4swJUHT4V27RQnQ6GzhiHLydEKp7ANm1Uh4qiqphpI7M3pSiZ
OVwRKIqCZZgH6IxEG3PkGOyebk/xM5uz6+8sYn4+nDOlDaVb+fduDxBkyJTMdKJ82t0xgg8/Z19Z
kslAddnglmAA8d7nTonPJfGpCjjszIKa6C1rQFLJ1NMMSma0Y6UF0hCoXhth9v5/0M7USQiwRKZs
iszfx8TZt7pqAlwOSchl7qsgIK0yJBrQhQiYQpBOqiPbO7Qjd3c7jL1t/uiI3KxdLdFda1ZFj1ve
1hbYkuUprZkYWhbfLqaRO+x8Orc/2tccyIzN4WRvS5s9ZvSIFcOGVsZbWlsiQoglQhG9vhzrbAl6
2kAiexDs2He5hJTUCsDOOGtNQmTaSFsu1DQVmQn+LRUKvau3fG//FS2STcAmVdVAUXokcrdD0+hq
6/QH/P5GW0rcoQCDJoyio34P3Y0tFA4dDFIS6egiZ2AxSIh3h8kuLSLRE0VaCRweF+l4Ctu0MjvO
qTR6MoVQBLGOHgL52fhysnr1TYAi0Nq6wohEquBwIx4obBOsWBMkOaUDn/KpZ2O+GuSXeG4DXpu6
Tg/V24KUD4wzYkAcVYXtTT7iSY1xQyO0dbhp7XYyamCczm433TEHQ4sSOFSb9i4XW7d6GVMWI9tr
UFPnw4hrZPt0NjX6ME3BIcMi5HqNDBk+T+7eDFg+slOfaecEnAK8qk32gLSoGpxyYkVzew5tyW0I
O6bW7gpduG3P9rr3NlUvWhIpeTDc+Jf1ntIL/0Nq/eWxcfMWurq6HYahn9DS2lobi8cX5Rfm0bin
GaX3JmpVAa/XlTE5esPVkowpKgFVUQGJtCWKoiBUBcMwETaoqsC2ZaaOVFCEkln+lcy71o000rIQ
CByqwDBNIbEVkDiFi+q3F7Lm2TdBSiafewIlo4by8g1/YfJpx5NXNJD3n3uJ46+6lAV3P8K4uYcz
4qgZ1Dz3FhteWchpN/+Mtc+9SVdjC4qmkgxHmXbeKYSKC0AivV6P6fJ4bG3B8UeLqfc+UOrXTW8u
DtyxXnNk38z8RbGvriI/9B1sYN/N81+UUxLw2GxsCnDTfRVEupxYSH7xg1qmzujm5tsq2FIT4tH/
Xc26uiDzny/i7pu3sGRLDitWZXPDT7eTHbDZtM3H1X8cyxWX7OTsc/fy+xurKPLYjBzaxb8WFTK5
MsrQQUlyQ0Ym6vVl5IPMGIU8wJcSvf6U2G96ZWmSrHyDscVtnpgQI2fvbB6R80J44rqab/+oILd7
feDwW3jhsV99kzp/UNi2BcIWpmnmpJLpNj2t43F52LltFTu3rdpf78brPgwt762t3l9u3LVhf/mN
+TUf6fuO29bsL+/csnJ/uWbbAe1rP2w/vHJwJhNOSjSHEyOqs+yx55l1yslUzJxEZ6SdhrVbMGMG
jeu245uVha1bePCQ7o6jOVw4nC6Q0L6rgepXFrFj+RpyBpaQ6I7gDgbIKy/FMk1saaOqmlQ1Da34
hXlOZzxRkmUabiEcHBDh/BLfJBkTxi2xNUhaGSZ4VBvFkhnzKCU+agZ9BtIewaPzSggELe66bT3V
G0MEXRbrtoToiUNefpT312Xh85vEe2cW3Ya4JfcnlmuKZE+3wrwVuZQPi7FqY5Cjp3RgAE6fyfiR
EUpDqYzZ9UVJYAOaBK9EOiFlCywpcKk2DtuGtJIZ674v0Owt6yp+CTNGpYWS3TD99/dlXxVPnnVF
ACvylbX3a8TE8WPo7uo2KirLXywuKo4lEnGysrJZ+ObzfSSRRCCwLQvN5aSgrIxtK9fS1dVJoDSX
moUf4A76aampxVeQRbwrTM2qD9BTOjuXr2PA2GFI2yZ7QDFb316B2+/F4XIQbU+hqCrxrjCegL93
MzZzC4aWbGlT1WQqyysUhwLY5peYwfdFkHJs2pIOVq8OsWxFDnva3CChtDDFrJmdTB0XIS/XgG7x
xTY4BBlCSoEpFdxOi1yfzvOLC2htc1Ock2bpshxOmNOG4pIkNIEtM8t02pnxbaJJlZJBCWRScPtf
yxk5PIzLa2IYAqfbxhU09//CyheCDYRs4qrCpp0BFi3OY3u9H8OE3JDJlPE9TD+kiyF5KZSwAEP0
rhq97VXoCTtYuTSXdJ0+zV9ZP0KXysov+On/UeiGiSklqVTaF4vFjGQyiar24eHFjM2FbVnYDpMj
fno+Kx9/mUhXJ6WTh1M+czwDRlfRUduImdYpO2Q0LS27GT13Nl31TXQ1NpNXXsrU75wEZI526okU
pfEk7bsaSHSFYXBp715sRgG0YRPGO/Nfez07S1qORqliBEyUXtvtc4VVJEYOLFiTxz33l9PS6WB0
RYSQK2NINzS7+c2toygt0PnRD3Zx1OQOHN0yYz58BhlcCcn3Tt3L9XdXccF54/EEbC77bj3bN4c4
ZGyE/KI0G1bl0LnbTVeLh3v/Vsbg3BRbd/m55a4hXPPDelBUKrN1jp3WzRvLi5g7vY36ugCKrZDs
cbJyQxYjByaoLEpCks8mpwRybTa2+PnrfRWsXJdF5ZAIpTk6lgqJuMp9TwzivseGcP65DXzrpCZC
STuzCu4zEx02a+tCdDySx/nhdO4zFdHilKUw7sIfsuHhvg2nrlm7gXA47Ozo6D6tsCB/UywW252T
k9OnMkHmriJdT+Hyujn6qgsB0FMpBoyuQkpJ3pABWIaJ5nJiGxb0RoRS0RiKplI0vBwhlIzpJ2Vv
RrOCbdnoyWRvICDz4rVyn1Zid5tF1Ukvi/N8TJrQiEfYYCmfff2XAnoOPDp/APf+bznHH9VObnaS
3XUe4lbGzwj5ba68uJadu/389g+jaLy4lgtPasDRTcamPpjyCSChMLIixs1/2Mb690IMHRpn0OAE
WW6DESNj+AaaVL/fhS9tUTU6RkpXGBpKUl4VJy0FXtNmckWYgquSlIZSTBvbRUFxmp5ID17Tpqwq
gYkgy2t+vmkkgWybxVuzufG2UZQOSPCji+rYsdVL3IS0FDg1yUlHtOELSh59fAjbawNcd0UN2Vgf
hmoFJNtVpsQNcqThVdu6830pnabKQX2scODzeUmlU1YoGNial5uzV1FUCvJz+1qsDzMFUmnMtN77
TO7fXd53L6qRyvzf/gQDKbF0E0s3D9qt5nT0HuA54O5Ta0d0XPfgZOGWiw1mXt/IaeM7UMPi85Uj
y+bFxYXc/1A5R01tZ9LYTiqq4ryyIp+XV+TywpIC3t+Uw7ARUQaVxjhxVhOPPFXGC+8UI33y0/sX
YAUlL79TyDMvFnPs4W04HDZ331+OryATurz15kpqm9xUjI/RnXCwo9FH8eAU+Tk61TtC7O1xYSqS
15YVsrImi6rxMeYtLmTRuhyyB6YJBky27/ERt1Rw8NkRLZ/N+voAt9w5nIqSKLMmtjF7Zgcba4M8
8VYxr32Qy/NL8ykamKagMMnJRzSy5oNs7nywnIQXUHvTSASkUiouCZpta850Ojew4g0xfNyYvlY3
pk6ewGGzZpqjRw1fOGjQgM3Dh5dTUlzY12IB+/T+AGXZVxbiQM3vTd2X+8K9n9Hfwe/XVjZuaBo+
eXpH9qW/rOdbs1rItqzPNl16ozr1nW7+/tBgDpvURkdacPXNwwl3a5w1twlVCBya5DtnNLJtm49f
/XkYTWGNWRNb+cdTA6lp9Wb2CD4OkXFAG1IullTn8dxLA9mwLcgHW7J45e2BLFudw/Z2L68uLOXt
RUXUtnl5Y0ExL706iG2tPha/n8szz5WxriFI9R4/L7xYxrLVeexq8fDagmLeeGcANc0+3no3n389
O4j1ewJ0WNqnW4GqJCYV7n98EPmhJKWlUX55ZxVPvVTMBec3kJulY5uCI6d1khNKc93tlby9Npsz
jm7kzQWFvPV+HgQzfogAnEGLelUlrZsiJ+B3XgLqpInj+1rX2LJtB9Wbtjpqduw6vrZu9/idO+uo
q9/T12L9x9Cb/vmRZ4rqdr67q8a7q22bhmKTiXSIz+nFI3ltcT5S2Dj9Bgs3ZJFC8vT8QuYe3s7E
yjiTR0WZOb2bp+YXobgM3lqbjaLZaG6Ld5bnIlU++jm9s+/aPQF+cs14CvKS3HFjNfc8PIh164L8
+eZ1NNZ7uOvPQ7nld9VMndTJNdeOYfK4Tq68fBt/uG0oLZ0O7rplPe8tyeOxZwdy6+1ryc9NcfXV
Yznr1D2cdW49v79tGB6/wc03buSJ50q59b5Kom6RCft+HC7Jhm1Btu72MqwqwsvL83AFdJ58pYii
bJ1vz20hx2fxvbnNLF2ZTdwQbG70sr7ez9DyCC++XUjMVMCRmbFGDg/TOSfGKwUeuzEWT84Aa+fK
1X2tF3R19dDW3iGi0XientYDiUQK0zT+/Y7/y3Hg6qGtXHD3ohlHXmY8+FSy6uoL68eW5cnMLi69
cfED9UMCqiSSVlm6OoshAxNsqvfT2uki6DdZvSFEfYOX235Zg0u1WVadxco1WbiydXqiDlZtCVGY
q7O1JkDSVvEK+6ObdjZ4NJuAW2fUwBiHjggzP6+A0oI000eF2b4qQFe7g+kjevBhscClM6osRkVF
nHmhNMNKExw6sof3Qjm4NQczR/Zg7HWw0m0wqSKC9MICv86o0jiHVoV5MzeF323us14+CQ221vpw
OWx2t7ipafASCBk0tbh5YV4Rv/hxLafMaAePYOGyPAwEZlqwZluAE2d2smRVDnva3VQWxlm/sZCV
HROIT/TSFmrWqZctJ8+dK3/wnW/xzz5WiDmzpiFtdEUVLymKiOXkZuPQ/n//wVXxEZNL+/YP/yrb
K+5b9uYH37lOe8K4/ZJTmqsKc22cLolHlSj2xxYIpyRtOOhJaIwsizNuWIrirDTZQZOB+TqDclO8
/nYBmmoza0IX1/9oF91hDaeAEcNibK7xs6EmQDyt4FXsD0OwvU7ymJI4995QjUdKnFG46Uc7ERK8
HfC9k/fy3ZP34g9Lpg6I8sAfN+K1bTRDctcvtuGUElcnXHV+PVKAvwWOGdvBrPFd+HuTl/5y7Wbc
UuKMSn7/wxoUwJvmk4c3BFimYE+7C5/fYNLwCA7VxuexyAuZTBoT5YP3QqyqzmLukW38/Lv17G7y
kEgpDB+SIGUJ5i3OJ66rLNs8kHs3X4LTpZDr70YbPt6VnWtMUtdseulfby6J0seor2+grb1dGTNq
VP6gQYOUnp6etuLior4WqxfyQwf56+zVltjmh2kCGpqP+IpfyebIla9mbSfe+EDdhaVF8aKCUDK/
NCdRmBtIet0u6VA1W3F4pHB5bGGqiognhebz2kw/JMyg/BSqZuPNsXEEbHbVBlAVi+OObGf0+CjR
Fo10WCMrYOJy2dhSAVVkcoMONElkZiMsSyWTyiDBn9m5B1tkiCMAS+AQNlmKvX/VCqpWb1ngV6z9
9VzCxnVAvZDK/noB1cr0bYnMnsKBECAcYNoKmgrjJkYpzk8hDQVnyCa3NEXN9lxqakJ0Tekmu1An
EVNp0t1oEgr8Oh6XhWUqvNFyCtmeKD+e+ACJWJK75x/rSA87/Gz3iEFvlN71izdO64rx4rwn+0zV
dtbVE+4JO1TVcXRnV/eWWCy+c29Tc5/IcsBxFBACVdFQvsbVSUpQ1APs8t6tJO2pv12Mo/h4BpQ6
7KHvPbngCeOOdwtHtfoDPqNEGHtKBxU7C4x0NOBx4jDSUUdJvttlGVFvQ4t+SjzB6OvvruC9LQG8
XhMr5uQPV+zkN7/ajsdh8dhzJdzw10q8eSkSEQfjyuMMLUuQE4oRliZGykZa/86w/oMQIFTIyorS
sz7IPf8o461V2bh9BpEuD6fPbuPW62s4fW4L720McclvRqErEj2pMWJgiktO34NbNVi7KsvsyS9T
c8LviOVrs2junMKMicfyQbQnP+pwjpl45l1vdQwp/bxdm/8ogv4gqaRu5eXlbC0tLWns7g5TUlLI
0r4QRmZmayklKhpu1Y8eTH1mk4MkIH9G/xLN4UKmLBRNRUo78wzAaH6dvc2vs+mI3zCVGjMlSnoS
qVRPOuXYkrZc6GYKMMh11/G7p+tFlXhIHTp6XndjS+fNFaVJ59oaP05VklWcZOToKPf8YwjCbXPs
cW2UPZ8krAMekwkjo7SHbWvXnkD1v16e2iYtHF/hNx2+MTicWNU1ikuXialVZTH3extCqJrEcFoc
fUwHry/PY/4bBfz0snomjY+wbrsPVZhMHt9Nd1Khrsu/4snX0/NKz4ieFzWnjB5behUTjxhAU1cj
y6tXJ1TD3nv283fYiy79WZ+O85hjDsOwLMPjci5waQ6joCAPl8PRN9+524nb7cGXHWLn+6t5+pc3
fabjvs9iiiQU0mnweW18btl7SdzBoSgCaUt69rbiDvrQHI6P3mKxYeEfP9GooRpOOOd2QllBVr1X
wp2/eV0OG7/AHFY+eOGmjbsavn9ye+XUKh9L1gW54MLd7Nzt5ZGXC7AtwchhUS46by/X3lzFrAk9
DM6LsmRV1nZf0aifvLFyxgaH33B8Xty3ryAAqWNbamNwT/PCu9Ix/fRTD+3igXnFHDWzk7KyJFf+
dhhba32UliT4zmnNrLp+BBUDUhw3vY2/PFYQ9vgLH4rpf3qInhe7/GPLb2/3R0MduzawPtJBOpVa
MCjNokuv+TXqhsY+HevSZSuIxWLO4qLiuTnZ2fXJZHJpMBDoG2EUBaGpOL1u0dbQTPu2eoTy6Tu7
EoluqMwZozO+SueVZR42tSh4XJ+ePiN7z7F4QgFy8nNRvugFX68984v95V3V93P+hVfzkyvOr77o
B/X/eOO92HVzZ7V6hxSkmTopzJ0PluHymFiW4N77y7jp+hp+edEefBgsXKmlTVH65BWXn7Bqydvv
6eFw+HM3QPoUtuTlF/b0lA4teGDxmtSU7xzbNfCi4zSOOb6Zea/m09DsoqQ4yctvF1A1LMENl9eS
q5nMX+ihrrlo/oQpoVeF8aAc1BV6cc/u5pl7Zg843xsqEKyPbo1u2HVHtUNtrtqQQJb1rWPavLeF
aFeH4nZ6yn0+fyISjeH1+ftEFmnZSNMStmkLh8eDPxT81JldAN0xhUmVOrf9uovyUo3pczR+clsW
SR3czs82k2zLEqZpKaqqfLW7Tx/55+0Es6ZYV1z57X8+/kjPwOcWtlx01cVNLsNQiEY1fJ6Ms2pa
gq4WBzNmNHPbHUV6bWfxwxd+/6T7N22o1wM5Odx771/65Mv+Mvjpz2/g7DOPWXTpZbfc9uA8+7pL
zm4tKK+M8dDTJWRlmTicEq/DoKnBxakntnD/vbm8uDRn0dHHTvzTM0/c0QpwwdXXdrQsXvMnT0Hu
SGtg4Uh9/c6/fTfsf7/tqJEEj8mmtbWDW2+/B4dDI5GMI1Bwu9243S6Qgp5IhKFDy9m7Zy+WbRNP
JAkGfFiWjW4YBAN+wpEIqqqhqgqxWByHw4HH42L06JFs3LgFp8NBMOSnuyuCaVokEjECgSCWZRON
Rti8dbuuCOVphIgU5uce9LeIvwkUFxcxrKpCb25tC6dTKVwO7dMnSwG6IcjKMskOmBBVGFRs4HNZ
RBIKLof81J9TE0JgmKbtdDp6HJpD/0pEePHV14n0rKZq5NzWqdNG/e69D5zNv/ufwPcHl6aGHDGu
Fb/HRlUkKV3w7MtZbK4vbYilQ/8YO7Hq3vlvLuvI8bl547WH++SL/jL4n/v+zo8v+wPV1bvSp551
5P1PPUHXnx/3XvXSMn3CyJKIOqmqC4nAlpK6RheX/nJoT08s7+WKkXm3PfPEXVtWrFpIc1MP1Uur
eXjhvK2nfu/SO5uXrzskJNUne06drJjptA9JikyMzEMmnpUC3GQyvRJkkkBcvc/t3noGoPfWE2TS
Bp29dfd5lu4D6nl6nyV7n6u9fauAOysrmO7qjFgSy+N0OeKxWJy8vL5JunO7XVz785+k3nxr4WIh
ODOt63mKomDbn0zLEYDPZbFqg8KDT3uYPFbn2dc87GmXeFwG+sFcC5nxEXRdx+Fw1kwcN/r5YMDf
9G/R/tIf/oQ9LRGmTx7keurlrbPjscjxBVmxWX63VSCRIpbQ2mPJ3CVuf3B+YV58qY0vnUhL5h45
nmuv+cW/89HfKG657XbeXb6B5YnHKO08fWw0YhxfmJs4LuBJDFEU1KQuIl1h39q07X3jzFMmv/HI
k4s62+qe4fIf38S9f/srUkr+eONt+Dye4pXrq08zpf22bctBumEcN3xY5byamtpGVVUvVFW1IScn
NK+7u+dUhJIT8PseTiZTk+KJ5JzS0uLnFCGiexqbztdUdf2AAcVv721qPk+AWjW04olddQ2zY9HY
xIKCvKcVRdFaWtq+5fd73x8+bOjyTZu3XeB0OhIDSwe8UN+w5zjbloOzQoFHotF4SSqdPt3rcb+Z
lRWq2bx5+/Vut+tRwzAWBoN+Xn7usW/8u3762Rd48pkXqawYEli2fOX3Oru6L3W7XdmqohoHM3QE
kDYyaux1S6K9K4GqyE8xi4SwLMtpWta2/LzcP11x2cVvFxYUmv8PfcoveCqn1HwAAAAldEVYdGRh
dGU6Y3JlYXRlADIwMjEtMTEtMTJUMTc6MjU6NDkrMDA6MDDm3ImOAAAAJXRFWHRkYXRlOm1vZGlm
eQAyMDIxLTExLTEyVDE3OjI1OjQ5KzAwOjAwl4ExMgAAAABJRU5ErkJggg==" />
</svg>
);

export default Logo;
