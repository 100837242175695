import React, {Fragment} from 'react';
import { ImageField, List, Datagrid, TextField, DateField, EditButton, Filter, TextInput, SelectInput, useTranslate } from 'react-admin';
import BatchDeleteButton from '../../control/button/batch-delete-button';
import {TRUE, FALSE, GENDER_MALE, GENDER_FEMALE, USER_TYPE_AUTO_ENTERPRENER, USER_TYPE_COMPANY_AND_DRIVER} from '../../util/constant';

import {DriverStatusField, VerifyField, AvatarEmailField, FullNameField, GenderField, GenderFilterField, LockField, ValidateField, AvatarField} from '../../control/field/index'

const BulkActionButtons = props => (
    <Fragment>
        {/* <BatchDeleteButton label="Supprimer" {...props} /> */}
    </Fragment>
);
const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.driver.list')}</span>;
};

var driverTypes = [];
driverTypes.push({id: USER_TYPE_AUTO_ENTERPRENER, name: "Auto-entrepreneur"});
driverTypes.push({id: USER_TYPE_COMPANY_AND_DRIVER, name: "Autre"});

const UserTypeField = ({ record = {}, label}) => {
    return <div>
        <span class>{record.user_type == USER_TYPE_COMPANY_AND_DRIVER? 'Autre' : 'Auto-entrepreneur'}</span>
    </div>;
}

const UserTypeFilterField = ({ record }) => {
    return <span>{record.id == USER_TYPE_AUTO_ENTERPRENER ? 'Auto-entrepreneur' : 'Autre'}</span>;
};

var genders = [];
genders.push({id: GENDER_MALE, name: 'Monsieur'});
genders.push({id: GENDER_FEMALE, name: "Madame"});

const ListFilter = ({ permissions, ...props })  => (
    <Filter {...props}>
        <TextInput label="Email" source="email" alwaysOn resettable/>
        <TextInput label="Mobile" source="mobile" alwaysOn resettable/>
        <SelectInput label="Type" source="user_type" alwaysOn choices={driverTypes} 
            optionText={<UserTypeFilterField />} allowEmpty emptyText="Tous les types" emptyValue='0' 
            initialValue='0'
            />
        <SelectInput label="Civilité" source="gender" alwaysOn choices={genders} 
            allowEmpty emptyText="Tous les types" emptyValue='0' initialValue='0'
            optionText={<GenderFilterField />}/>
        <TextInput label="Nom" source="last_name" resettable/>
        <TextInput label="Prénom" source="first_name" resettable/>
        <TextInput label="Ville" source="city" resettable/>
        <TextInput label="Code postal" source="postal_code" resettable/>
       
    </Filter>
);

const ListDriverDeleted = ({permissions, ...props}) => {
    const translate = useTranslate();
    return(
    <List title={<Title/>} {...props} perPage={50}
        filters = {<ListFilter permissions={permissions} />} 
        bulkActionButtons={<BulkActionButtons />} exporter={false}>
        <Datagrid rowClick="show">
            <AvatarField source="photo_url" label="" />
            {/* <TextField source="user_number" label="ID"/> */}
            <TextField source="id" label="ID"/>
            <GenderField source="gender" label={translate('civility')}/>
            <TextField source="last_name" label="Nom"/>
            <TextField source="first_name" label="Prénom"/>
            <UserTypeField source="user_type" label="Type d'entreprise"/>
            <TextField source="postal_code" label="Code postal"/>
            <TextField source="city" label="Ville"/>
            <TextField source="mobile" label="Mobile"/>
            <TextField source="email" label="Email"/>
            <DateField source="create_time" label="Date d'inscription"  locales="fr-FR"/>
            <DateField source="delete_time" label="Date de suppression"  locales="fr-FR"/>
            <ValidateField source="activated" label='Activé'/>
            <ValidateField source="validated" label='Validé'/>
            <ValidateField source="locked" label='Verrouillé'/>
            <ValidateField source="deleted" label='Supprimé'/>
        </Datagrid>
    </List>);
};

export default ListDriverDeleted;

