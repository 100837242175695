import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form'
import { change, submit, isSubmitting } from 'redux-form';
import {
    fetchEnd,
    fetchStart,
    required,
    showNotification,
    Button,
    SaveButton,
    SimpleForm,
    TextInput,
    crudUpdate,
    UPDATE,
    FileField,
    REDUX_FORM_NAME
} from 'react-admin';
// import RejectIcon from '@material-ui/icons/Close';
import LockIcon from '@material-ui/icons/Lock';
import ActionSend from '@material-ui/icons/Send';
import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
// import RichTextInput from 'ra-input-rich-text';

import jsonDataProvider from '../../provider/json-data-provider';
import {httpClient} from '../../util/function'
import {BACKEND_UMS_URL} from '../../util/variant'
import {Status} from '../../util/constant'

import { log } from 'util';
const dataProvider = jsonDataProvider(BACKEND_UMS_URL, httpClient);


class CustomerLockButton extends Component {
    state = {
        error: false,
        showDialog: false
    };

    handleClick = () => {
        this.setState({ showDialog: true });
    };

    handleCloseClick = () => {
        this.setState({ showDialog: false });
    };

    handleSaveClick = () => {
        console.log('Reject vehicle button props: ' + JSON.stringify(this.props));
        const { submit, record } = this.props;

        // Trigger a submit of our custom quick create form
        // This is needed because our modal action buttons are oustide the form
        // alert('Click');
        submit(record.id);
    };

    handleSubmit = values => {
        // const { fetchStart, fetchEnd, showNotification, record } = this.props;
        // console.log('Reject doc button props: ' + this.props);
        // Dispatch an action letting react-admin know a API call is ongoing
        // fetchStart();
        const { record, crudUpdate, basePath } = this.props;

        // As we want to know when the new post has been created in order to close the modal, we use the
        // dataProvider directly
        // values['id'] = record.id;
        // values['status'] = DocStatus.REJECTED;

        console.log('Reject doc button values: ' + JSON.stringify(values['motif'])); 
        // , reason: values['reason']
        // crudUpdate('vehicle_status', record.id, {status: DataStatus.REJECTED, reason: values['motif']}, basePath, 'show', true);
        crudUpdate('customer_lock', record.id, {locked: Status.LOCKED, reason: values['reason']}, basePath, 'show', true);

        // dataProvider(UPDATE, 'vehicle_status', { id: record.id, data: {id: record.id, status: VehicleStatus.LOCKED, reason: values['reason']}})
        //     .then(({ data }) => {
        //         this.setState({ showDialog: false });
        //     })
        //     .catch(error => {
        //         showNotification(error.message, 'error');
        //     })
        //     .finally(() => {
        //         // Dispatch an action letting react-admin know a API call has ended
        //         fetchEnd();
        //     });

        this.setState({ showDialog: false });
    };

   

    render() {
        const { showDialog } = this.state;
        const { isSubmitting, record } = this.props;

       
        const onSubmit = async values => {
            // window.alert(JSON.stringify(values, 0, 2))
            this.handleSubmit(values);
        }

        // var id = record.id;
        let message = "Êtes-vous sûr de vouloir verrouiller ce compte?";
        let submit;
        return (
            <Fragment>
                <Button onClick={this.handleClick} label="Verrouiller">
                    <LockIcon color='primary'/>
                </Button>
                <Dialog
                    maxWidth="800xs"
                    open={showDialog}
                    onClose={this.handleCloseClick}
                    aria-label="Verrouiller un compte"
                >
                    <DialogTitle>Verrouillage d'un compte</DialogTitle>
                    <DialogContent>
                    <Form
                            toolbar={null}
                            onSubmit={onSubmit}
                            render={({ handleSubmit, form, submitting, pristine, values }) => {submit = handleSubmit
                            return (
                                <form id="lock-customer-form" onSubmit={handleSubmit}>
                                <div>
                                    <span>Saisissez le motif</span>
                                    <TextInput multiline source="reason" label="" fullWidth={true} validate={required()}/>
                                </div>
                                
                                {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
                                </form>
                            )
                            }}
                    >

                    </Form>
                    </DialogContent>
                    <DialogActions>
                        {/* <SaveButton
                            saving={isSubmitting}
                            onClick={this.handleSaveClick}
                        /> */}
                       
                        <Button label="ra.action.cancel" onClick={this.handleCloseClick}>
                            <IconCancel color="secondary"/>
                        </Button>
                        <Button label="ra.action.save" onClick={() =>
                                // { cancelable: true } required for Firefox
                                // https://github.com/facebook/react/issues/12639#issuecomment-382519193
                                document
                                .getElementById('lock-customer-form')
                                .dispatchEvent(new Event('submit', { cancelable: true }))
                            } saving={isSubmitting}>
                            <ActionSend />
                        </Button>
                        {/* <button
                            type="submit"
                            onClick={() =>
                                // { cancelable: true } required for Firefox
                                // https://github.com/facebook/react/issues/12639#issuecomment-382519193
                                document
                                .getElementById('exampleForm')
                                .dispatchEvent(new Event('submit', { cancelable: true }))
                            }
                            >
                            Save
                            </button> */}
                    </DialogActions>
                </Dialog>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    isSubmitting: isSubmitting()(state)
});

CustomerLockButton.propTypes = {
    label: PropTypes.string,
    push: PropTypes.func,
    record: PropTypes.object,
    showNotification: PropTypes.func,
};


const mapDispatchToProps = {
    change,
    fetchEnd,
    fetchStart,
    showNotification,
    submit
};

// export default connect(mapStateToProps, mapDispatchToProps)(
//     LockVehicleButton
// );
export default connect(undefined, { crudUpdate })(CustomerLockButton);

    // handleClick = () => {
    //     // const { push, record, showNotification } = this.props;
    //     // const updatedRecord = { ...record, is_approved: true };
    //     // fetch(`/comments/${record.id}`, { method: 'PUT', body: updatedRecord })
    //     //     .then(() => {
    //     //         showNotification('Comment approved');
    //     //         push('/comments');
    //     //     })
    //     //     .catch((e) => {
    //     //         showNotification('Error: comment not approved', 'warning')
    //     //     });
    // }

    // render() {
    //     return (
    //             <Button label={this.props.label} onClick={this.handleClick}>
    //                 <ActionEmail/>
    //             </Button>
            
    //     );
    // }
// }

// SendMailButton.propTypes = {
//     label: PropTypes.string,
//     push: PropTypes.func,
//     record: PropTypes.object,
//     showNotification: PropTypes.func,
// };

// export default connect(null, {
//     showNotification,
//     push,
// })(SendMailButton);

// import React, { Component, Fragment } from 'react';
// import PropTypes from 'prop-types';
// import { connect } from 'react-redux';
// import { Button, Confirm, crudUpdateMany,  crudUpdate, useUpdate} from 'react-admin';
// import ValidateIcon from '@material-ui/icons/Check';
// import LockIcon from '@material-ui/icons/Lock';
// // import UnlockIcon from '@material-ui/icons/LockOpen';
// import {Status} from '../../util/constant'

// class CustomerLockButton extends Component {
//     state = {
//         isOpen: false,
//         record: {locked: 0},
//     }

//     // constructor(props){
//     //     super(props);
//     //     const {record} = this.props
//     //     this.setState({validated: record.validated});
//     //     console.log('Validate button state ' + JSON.stringify(this.state));
//     // }

//     // componentDidUpdate(prevProps, prevState) {
//     //     if(prevProps.record!==this.props.record){
//     //       this.setState({record: this.props.record});
//     //     }
//     //   }

//     //   static getDerivedStateFromProps(nextProps, prevState){
//     //     if(nextProps.record !== prevState.record){
//     //       return {record : nextProps.record};
//     //     }
//     //     else return null;
//     //   }

//     handleClick = () => {
//         this.setState({ isOpen: true });
//     }

//     handleDialogClose = () => {
//         this.setState({ isOpen: false });
//     };

//     handleConfirm = () => {
//         console.log("Handle button props " + JSON.stringify(this.props));
//         const { basePath, crudUpdate, resource, record } = this.props;
//         // crudUpdate(resource, record.id, {validated: 1}, basePath + '/' + record.id + '/show');
//         crudUpdate('customer_lock', record.id, {locked: Status.LOCKED}, basePath, 'show', true);
//         this.setState({ isOpen: false });
//     };

//     static defaultProps = {
//         confirmTitle: 'Verrouillage',
//         confirmMessage: 'Are you sure to mark these objects as handled?',
//         locked: 0,
//       }
    

//     render() {
//         let message = "Êtes-vous sûr de vouloir verrouiller ce compte?";
//         return (
//             <Fragment>
//             {typeof(this.state.record) !== 'undefined' && !this.state.record.locked && 
//                 <Button label='Verrouiller' onClick={this.handleClick}>
//                     <LockIcon color='primary'/>
//                 </Button>}
//                 <Confirm
//                     isOpen={this.state.isOpen}
//                     title={this.props.confirmTitle}
//                     content={message}
//                     onConfirm={this.handleConfirm}
//                     onClose={this.handleDialogClose}
//                 />
//             </Fragment>
//         );
//     }
//     // handleClick = () => {
//     //     const { push, record, showNotification } = this.props;
//     //     const updatedRecord = { ...record, is_approved: true };
//     //     fetch(`/comments/${record.id}`, { method: 'PUT', body: updatedRecord })
//     //         .then(() => {
//     //             showNotification('Comment approved');
//     //             push('/comments');
//     //         })
//     //         .catch((e) => {
//     //             showNotification('Error: comment not approved', 'warning')
//     //         });
//     // }

//     // render() {
//     //     return <Button label="Approve" onClick={this.handleClick} />;
//     // }
    
// }

// // BatchHandleButton.propTypes = {
// //     confirmTitle: PropTypes.string,
// //     confirmMessage: PropTypes.string,
    
// // };

// export default connect(undefined, { crudUpdate })(CustomerLockButton);
// // export default ValidateButton;

// // HandleButton.propTypes = {
// //     push: PropTypes.func,
// //     record: PropTypes.object,
// //     showNotification: PropTypes.func,
// // };

// // export default connect(null, {
// //     showNotification,
// //     push,
// // })(HandleButton);