import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { showNotification, Confirm, crudGetOne, Button } from 'react-admin';
import ArrowBack from '@material-ui/icons/ArrowBack';
import {DataStatus} from '../../util/constant'

class BackButton extends Component {
    handleClick = () => {
        this.context.router.history.goBack();
    }

    static contextTypes = {
      router: () => true, // replace with PropTypes.object if you use them
    }

    render() {
        return (
            <Fragment>
                <Button label="Retour" onClick={this.handleClick} disabled={false}> 
                    <ArrowBack/>
                </Button>
               

            </Fragment>
        );
    }
    
    
}

export default BackButton;

// HandleButton.propTypes = {
//     push: PropTypes.func,
//     record: PropTypes.object,
//     showNotification: PropTypes.func,
// };

// export default connect(null, {
//     showNotification,
//     push,
// })(UnhandleButton);