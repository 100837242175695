import React, { Component, } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import { crudGetOne, UserMenu, MenuItemLink, SimpleShowLayout } from 'react-admin';
import UserIcon from '@material-ui/icons/People';
import { Title, TextField } from 'react-admin';


import {BACKEND_UMS_URL} from '../util/variant'

// const images = [
//     {
//       original: 'https://i-thethao.vnecdn.net/2019/09/29/solskjaer-5948-1569733313.jpg',
//     //   thumbnail: 'http://nobbitech.com:3002/uploads/Untitled.pdf',
//     },
//     {
//       original: 'https://picsum.photos/id/1015/1000/600/',
//     //   thumbnail: 'https://picsum.photos/id/1015/250/150/',
//     },
//     {
//       original: 'https://i-thethao.vnecdn.net/2019/09/29/download-jpeg-3444-1569733314.jpg',
//     //   thumbnail: 'https://picsum.photos/id/1019/250/150/',
//     },
//   ];


class Foo extends Component {
    state = {
        config: {
            email:'', 
            first_name: '', 
            last_name:'', 
            agency:{name:''}, 
            role:''
        }
    }
    componentDidMount() {
        this.fetchConfig();
        // const profile = localStorage.getItem('profile');
        // alert(JSON.stringify(profile));
        // this.setState({profile: profile});
    }

    // if (!options.headers) {
    //     options.headers = new Headers({ Accept: 'application/json' });
    // }
    // const role = localStorage.getItem('role');
    // const token = localStorage.getItem('token');
    // options.headers.set('Authorization', `Bearer ${token}`);
    // options.headers.set('Role', `${role}`);

    fetchProfile = () => {
        const token = localStorage.getItem('token');
        // options.headers.set('Authorization', `Bearer ${token}`);
        const request = new Request(BACKEND_UMS_URL + '/config', {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json', 
                                    'Authorization': `Bearer ${token}` }),
        })
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }

                response.json().then(json => {
                    console.log("Get config response json: " + JSON.stringify(json));
                    this.setState({config: json});
                    return json;
                  });
              
            });
    };

    render() {
        // const { crudGetOne, profile, ...props } = this.props;

        return (
            <SimpleShowLayout>
            
              <Title title="Règlages du système" />
              
        </SimpleShowLayout>
        );
    }
}

// const mapStateToProps = state => {
//     const userId = localStorage.getItem('user_id');
//     console.log("Stored user id: " + userId);

//     const resource = 'profile';
//     // const id = 'my-profile';
//     const profileState = state.admin.resources[resource];

//     console.log("Profile state: " + JSON.stringify(profileState));
//     const profile =  profileState ? profileState.data[userId] : null;
//     if(typeof profile !== 'undefined')
//         profile.id = 'my-profile';
//     console.log("Profile " + JSON.stringify(profile));
//     return {
//         profile
//     };
// };

// export default connect(
//     mapStateToProps,
//     { crudGetOne }
// )(Foo);
export default Foo;
