import React from 'react';
import PropTypes from 'prop-types';

import ActivateIcon from '@material-ui/icons/Check';
import NotActivateIcon from '@material-ui/icons/Clear';
import { TRUE, FALSE } from '../../util/constant';

const ClientField = ({ source, record = {}, label}) => {
    var client = JSON.parse(record[source]);
    console.log("Order client: " + client.email);
    return <div>
        <span>{client.email}</span>
    </div>;
    
};
    // <div>
    //     <span class>{record[source]}</span>
    //     <img src={record[source] == TRUE ? ActivateIcon : NotActivateIcon} 
    //         width='20' height='20'
    //         alt={''}/>
    // </div>;

ClientField.defaultProps = {
    addLabel: true,
};

export default ClientField;