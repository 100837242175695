import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ApproveIcon from '@material-ui/icons/Check';
import RejectIcon from '@material-ui/icons/Close';
import NewIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';

import { DataStatus } from '../../util/constant';

const styles = {
    flex: { display: 'flex'},
    
};

const useStyles = makeStyles({
    flex: { 
        display: 'flex', 
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: 'center',
    },

    approved: {
       color: 'green',
       alignSelf: 'center',
     },
     rejected: {
       color: 'red',
       alignSelf: 'center',
     },
     
     empty: {
       color: 'gray',
       alignSelf: 'center',
     },
     new: {
        color: 'orange',
        alignSelf: 'center',
      },
});

const DocStatusField = ({ source, record = {}, label}) => {
    // if(record[source] == DocStatus.APPROVED)
    //     return <div><ApproveIcon color='#0f0'/></div>;
    // if(record[source] == DocStatus.REJECTED)
    //     return <div><RejectIcon color='#eee'/></div>;
    // return <div><NewIcon/></div>;
    const classes = useStyles();
    if(typeof(record) !== 'undefined' && typeof(record.name) !== 'undefined'){
        const count = record.files.length;
        if(count > 0){
            if(record[source] == DataStatus.APPROVED)
            return <div style={styles.flex}><ApproveIcon className={classes.approved}/><Typography variant="body2" className={classes.approved}>Approuvé</Typography></div>;
            if(record[source] == DataStatus.REJECTED)
            return <div style={styles.flex}><RejectIcon className={classes.rejected}/><Typography variant="body2" className={classes.rejected}>Rejeté</Typography></div>;
            return <div style={styles.flex}><NewIcon className={classes.new}/><Typography variant="body2">Nouveau</Typography></div>;
        }
        return <div style={styles.flex}><Typography variant="body2">Vide</Typography></div>;
    }
    
    return <div><Typography variant="body2">Vide</Typography></div>;
}

    // <div>
    //     {/* <span class>{label}</span> */}
    //     <img src={record[source] === DocStatus.TREATED ? handledIcon : (record[source] === DocStatus.UNTREATED ? unhandledIcon : archivedIcon)} 
    //         width='20' height='20'
    //         alt={'Etat de traitement'}/>
    // </div>;

DocStatusField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

DocStatusField.defaultProps = {
    addLabel: true,
};

export default DocStatusField;