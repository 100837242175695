
import React, {Link} from 'react';
import { ReferenceField, SimpleShowLayout,TextField, Show, useTranslate } from 'react-admin';
import { EditButton } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
// const Title = ({ record }) => {
//     return <span>Mon profil</span>;
// };

import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import {locationDict} from '../../util/variant'
import {ClientField, OrderStatusField, FormattedDateField, OrderTypeField} from '../../control/field/index'
import {AssignButton} from '../../control/button/index'

const cardActionStyle = {
    // zIndex: 2,
    // display: 'inline-block',
    // float: 'right',
    zIndex: 2,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    padding: 0,
};

const useStyles = makeStyles({
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
    colorPrimary: {
        color:'#F4004E',
    },
});

// const changePassword = () => {
//     // window.location.href = '/#/change-password';
// }

const ShowActions = ({ basePath, data, resource }) => (
    <CardActions style={cardActionStyle}>
        {/* <AssignButton/> */}
        {/* <EditButton basePath='/my-profile' record={data} /> */}
        {/* Add your custom actions */}
        {/* <Button color="primary" onClick={changePassword}>CHANGER LE MOT DE PASSE</Button> */}
        {/* <Link to="/change-password">Changer le mot de passe</Link> */}
    </CardActions>
);

const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.order_unassigned.show')}:#{record ? `${record.order_number}` : ''}</span>;
    
};



// const userId = localStorage.getItem('user_id');
// const role = localStorage.getItem('role');

const ShowOrderUnassigned = ({staticContext, ...props}) => {
    const classes = useStyles();
    return(
    <Show title={<Title />} {...props} actions={<ShowActions />}>
            
        <SimpleShowLayout>
            <OrderStatusField source="status" label = "État" className={classes.colorPrimary}/>
            <FormattedDateField source="create_time" format="format_date_time" label="Date/heure"/>
            
            <div/>
            <TextField source="order_number" label="Nº de commande" className={classes.inlineBlock}/>
            <OrderTypeField source="order_type" label= "Type de commande" className={classes.inlineBlock}/>
            <div/>
            <TextField source="c_last_name" label="Nom du client" className={classes.inlineBlock}/>
            <TextField source="c_first_name" label="Prénom du client" className={classes.inlineBlock}/>
            <div/>
            <TextField source="from_address" label = "Adresse de départ"/>
            <TextField source="to_address" label = "Adresse d'arrivée"/>
            <FormattedDateField source="pick_time" format="format_date_time" label="Prise en charge demandé"/>
            <div/>
            {/* <TextField source="d_last_name" label="Nom du chauffeur" className={classes.inlineBlock}/>
            <TextField source="d_first_name" label="Prénom du chauffeur" className={classes.inlineBlock}/>
            <div/> */}
            {/* <ReferenceField label="Client" source="client_id" reference="client" link="show">
                <TextField source="email" />
            </ReferenceField>

            <ReferenceField label="Chauffeur" source="driver_id" reference="driver" link="show">
                <TextField source="email" />
            </ReferenceField> */}
            
            <TextField source="total" label="Prix(XPF)"/>
            <TextField source="commission_amount" label="Commission (XPF)"/>
            <TextField source="driver_amount" label="Commission chauffeur(XPF)"/>
            {/* <TextField source="pt_name" label="Mode de paiement"/> */}
            {/* <TextField source="driver.email" label="Chauffeur"/> */}
            
            {/* <div/>
            <Divider/>
            <br/>
            <Typography variant="body2">Déroulement</Typography>
            <div/>
            <FormattedDateField source="start_time" format="format_date_time" label="Heure de début"/>
            <div/>
            <FormattedDateField source="at_pick_time" format="format_date_time" label="Chauffeur sur place" className={classes.inlineBlock}/>
            <FormattedDateField source="onboard_time" format="format_date_time" label="Passager à bord" className={classes.inlineBlock}/>
            <FormattedDateField source="end_time" format="format_date_time" label="Heure d'arrivée" className={classes.inlineBlock}/>
             */}
             <br/>
             <AssignButton/>
        </SimpleShowLayout>
    </Show>
)};


export default ShowOrderUnassigned;