
import React from 'react';
import {ReferenceArrayField, SingleFieldList, ChipField, RichTextField, TextField, CheckboxGroupInput, FormDataConsumer, 
    ReferenceArrayInput, AutocompleteArrayInput, TextInput, 
    validate, minValue, NumberInput, Edit, ImageField, SimpleForm, SelectInput, 
    RadioButtonGroupInput, required, ImageInput, useTranslate } from 'react-admin';
import CardActions from '@material-ui/core/CardActions';
import { ListButton, Toolbar, SaveButton } from 'react-admin';
import {PublishButton, DeleteButton} from '../../control/button/index';
import { withStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import { DateInput } from 'react-admin-date-inputs2';
// import MomentUtils from 'material-ui-pickers/utils/moment-utils';
import frLocale from "date-fns/locale/fr";
import {locationTypes} from '../../util/variant'
import {FALSE, TRUE} from '../../util/constant'
import {ValidateField} from '../../control/field/index'
import RichTextInput from 'ra-input-rich-text';
import Typography from '@material-ui/core/Typography';

const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

const CustomToolbarNoStyle = ({permissions, ...props}) => (
    <Toolbar {...props}>
    {typeof props.record != 'undefined' &&  typeof props.record.published != 'undefined' && props.record.published == FALSE &&
        <SaveButton />
            }
        
            {typeof props.record != 'undefined' &&  typeof props.record.published != 'undefined' && props.record.published == FALSE &&
        <DeleteButton content="Etes-vous sur de vouloir supprimer ce message?" redirect='list' {...props}/>}

        {typeof props.record != 'undefined' &&  typeof props.record.published != 'undefined' && props.record.published == TRUE &&
        <DeleteButton content="Etes-vous sur de vouloir supprimer ce message? Les utilisateurs ne le verront plus dans leur boîte de réception" redirect='list' {...props}/>}
    </Toolbar>
);
const CustomToolbar = withStyles(toolbarStyles)(CustomToolbarNoStyle);

const cardActionStyle = {
    zIndex: 2,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    padding: 0,
};

const EditActions = ({ basePath, data, resource }) => (
    <CardActions style={cardActionStyle}>
    {typeof data != 'undefined' &&  typeof data.published != 'undefined' && data.published == FALSE &&
            <PublishButton basePath={basePath} record={data} resource={resource} 
            content="Ce message sera envoyé aux chauffeurs/clients choisis. Êtes-vous sûr de vouloir publier ce message?"/>
        }
        
        <ListButton basePath={basePath}/>
    </CardActions>
);
const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.notice.edit')}: {record ? `${record.name}` : ''}</span>;
};

const Aside = () => (
    <div style={{ width: 200, margin: '1em' }}>
        <Typography variant="body2">Publier veut dire envoyer le message aux chauffeurs et clients choisis.</Typography>
        <Typography variant="body2">Laissez le champs Chauffeurs/Clients vide si vous voulez envoyer le message à tous les chauffeurs/clients.</Typography>
        <Typography variant="body2">Une fois publié, le message n'est plus modifiable et re-publié</Typography>
        {/* <Typography variant="body2">
            Posts will only be published once an editor approves them
        </Typography> */}
    </div>
);

const recipientNameField = choice => choice != null ? `${choice.first_name} ${choice.last_name} (${choice.email})` : '';

// const EnabledTextInput = ({ record, source, label, validate }) => {
//     if(record.published == TRUE){
//         return <TextField label={label} source={source} fullWidth={true}/>;
//     }
//     return <TextInput label={label} multiline source={source} fullWidth={true} validate={validate}/>;
// };

// const EnabledRichTextInput = ({ record, source, label, validate }) => {
//     if(record.published == TRUE){
//         return <RichTextField label={label} multiline source={source} fullWidth={true}/>;
//     }
//     return <RichTextInput label={label} multiline source={source} fullWidth={true} validate={validate}/>;
// };

// const ToRecipientsField = ({ record, source, label, reference }) => {
//     if(record.published == TRUE){
//         return <div>
//             <ReferenceArrayField label={label} reference={reference} source={source} basePath="">
//                 <SingleFieldList>
//                     <ChipField source='email' />
//                 </SingleFieldList>
//             </ReferenceArrayField>
//         </div>
//     }
//     return <div><CheckboxGroupInput source={source} label="" choices={[
//         { id: '1', name: {label} },
//     ]} disabled/>

//     <FormDataConsumer subscription={{ values: true }}>
//          {({ formData, ...rest }) => 
//             (typeof(formData.to_all_drivers) === 'undefined' ||
//             formData.to_all_drivers === 'undefined' ||
//             formData.to_all_drivers.length === 0 ||
//             formData.to_all_drivers[0] !== 1) &&
//               <ReferenceArrayInput {...rest} label={label} reference={reference} source={source}>
//                     <AutocompleteArrayInput optionText={recipientNameField}/>
//                 </ReferenceArrayInput>
//          }
//      </FormDataConsumer></div>
// };



const EditNotice = ({classes, permissions, ...props })  => {
    const translate = useTranslate();
    // const {record} = {props};
    // console.log('Notice record: ' + JSON.stringify(record));
    return (
        <Edit title={<Title />} {...props} actions={<EditActions/>}>
        <SimpleForm redirect="list" toolbar={<CustomToolbar/>}>
                
        <TextInput label="Titre" multiline source="title" fullWidth={true} validate={required()} />
            <RichTextInput multiline source="content" label="Contenu" fullWidth={true} validate={required()}/>

            <CheckboxGroupInput source="to_all_drivers" label="" choices={[
                { id: '1', name: 'Envoyer à tous les chauffeurs' },
            ]}/>

            <FormDataConsumer subscription={{ values: true }}>
                 {({ formData, ...rest }) => 
                    (typeof(formData.to_all_drivers) === 'undefined' ||
                    formData.to_all_drivers === 'undefined' ||
                    formData.to_all_drivers.length === 0 ||
                    formData.to_all_drivers[0] !== '1') &&
                      <ReferenceArrayInput {...rest} label="Chauffeurs" reference="driver" source="driver_ids">
                            <AutocompleteArrayInput optionText={recipientNameField}/>
                        </ReferenceArrayInput>
                 }
             </FormDataConsumer>
             {/* <ToRecipientsField label="Chauffeurs" reference="driver" source="driver_ids"/> */}

            <CheckboxGroupInput source="to_all_clients" label="" choices={[
                { id: '1', name: 'Envoyer à tous les clients' },
                
            ]} />

            <FormDataConsumer subscription={{ values: true }}>
                 {({ formData, ...rest }) => 
                    (typeof(formData.to_all_clients) === 'undefined' ||
                    formData.to_all_clients === 'undefined' ||
                    formData.to_all_clients.length === 0 ||
                    formData.to_all_clients[0] !== 1) &&
                    <ReferenceArrayInput label="Clients" reference="client" source="client_ids">
                        <AutocompleteArrayInput optionText={recipientNameField}/>
                    </ReferenceArrayInput>
                 }
             </FormDataConsumer>
             {/* <ToRecipientsField label="Clients" reference="client" source="client_ids"/> */}
            <ValidateField source="published" label='Publié?'/>
            {/* <RadioButtonGroupInput source="published" label="Publié?" 
            choices={[
                { id: '0', name: 'Non' },
                { id: '1', name: 'Oui' },
            ]} initialValue='0'/> */}

        </SimpleForm>
    </Edit>
)};

export default EditNotice;