
import React, {Link} from 'react';
import { UrlField, TabbedShowLayout, Tab, ReferenceField,TextField, Show, useTranslate , ReferenceManyField, Datagrid} from 'react-admin';
import { EditButton } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import StarRatings from 'react-star-ratings';
// const Title = ({ record }) => {
//     return <span>Mon profil</span>;
// };
import {OrderTypeField, OrderStatusField, FormattedDateField, OrderActivityTypeField, AnimalField,
    FullNameDriverField, FullNameClientField} from '../../control/field/index'
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import {locationDict} from '../../util/variant'
import {BACKEND_FILE_URL} from '../../util/variant'
import {PdfViewButton, DownloadButton} from '../../control/button/index'


const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
};

const useStyles = makeStyles({
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
    colorPrimary: {
        color:'#F4004E',
    },
});

// const styles = {
//     flex: { display: 'flex'},
//     images:{display: 'flex', paddingLeft:'1em', marginLeft:"3em" },
//     flexColumn: { display: 'flex', flexDirection: 'column', },
//     leftCol: { flex: 1, marginRight: '1em' },
//     rightCol: { flex: 1, marginLeft: '1em' },
//     singleCol: { marginTop: '2em', marginBottom: '2em' },
//     map:{ flex: 1, marginTop: '1em', marginBottom: '1em' },
//     button:{ flex: 1, marginTop: '1em'},
// };



const ShowActions = ({ basePath, data, resource }) => (
    <CardActions style={cardActionStyle}>
        {/* <EditButton basePath='/my-profile' record={data} /> */}
        {/* Add your custom actions */}
        {/* <Button color="primary" onClick={changePassword}>CHANGER LE MOT DE PASSE</Button> */}
        {/* <Link to="/change-password">Changer le mot de passe</Link> */}
    </CardActions>
);

const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.order_finished.show')}:#{record ? `${record.order_number}` : ''}</span>;
    
};

const TypeTextField = ({ source, record = {}, label}) => {
    const locationType = locationDict[record.type];
    return <span class="MuiTypography-root MuiTypography-body2">{typeof(locationType) !== 'undefined' ? locationType.name : record.type}</span>;
}
    
TypeTextField.defaultProps = {
    addLabel: true,
};

const OpenFileButton  =  ({ source, record = {}, label}) => {
    return <PdfViewButton file={BACKEND_FILE_URL + record.file_url}/>;
}

const DownloadFileButton  =  ({ source, record = {}, label}) => {
    return <DownloadButton fileUrl={record.file_url} fileName={record.file_name}/>;
    // return <DownloadButton onClick={(e) => alert("Fichier facture n'est pas encore disponible")} fileName={record.file_name}/>;
}

DownloadFileButton.defaultProps = {
    addLabel: true,
};

const RatingClientField  =  ({ source, record = {}, label}) => {
    // return <DownloadButton fileUrl={record.file_url} fileName={record.file_name}/>;
    return <StarRatings
    rating={Number(record.rating_on_client)}
    // rating={4}
    starRatedColor="yellow"
    numberOfStars={5}
    name='Notation au client'
    starDimension="32px"
        starSpacing="5px"

  />
}

RatingClientField.defaultProps = {
    addLabel: true,
};

const RatingDriverField  =  ({ source, record = {}, label}) => {
    // return <DownloadButton fileUrl={record.file_url} fileName={record.file_name}/>;
    return <StarRatings
    rating={Number(record.rating_on_driver)}
    // rating={4}
    starRatedColor="yellow"
    numberOfStars={5}
    starDimension="32px"
        starSpacing="5px"
    name='Notation au chauffeur'
  />
}

RatingClientField.defaultProps = {
    addLabel: true,
};

// const InvoiceContentPanel = ({ id, record, resource }) => {
//     const classes = useStyles();
//     console.log("Invoice record " + JSON.stringify(record));
//     var fileUrl = record.file_url;
//     if(fileUrl !== '' && !fileUrl.startsWith("http://") && !fileUrl.startsWith("https://"))
//         fileUrl = BACKEND_FILE_URL + fileUrl;

//     return(
//             <div>
//             <div style={styles.flexColumn}>
//                     <PdfViewer file={fileUrl} width='300' height='300'/>
                    
//                 </div>
//                 <div className={classes.noColorLabel}><span>Actions: </span>
                
//                 {/* {record.is_locked === Status.UNLOCKED && <VehicleLockButton record={record}/>}
//                 {record.is_locked === Status.LOCKED && <VehicleUnlockButton record={record}/>}
//                 {record.status == DataStatus.NEW && 
//                 <VehicleApproveButton record={record}/>}
//                 {record.status == DataStatus.NEW && 
//                 <VehicleRejectButton record={record}/>} */}
//                 </div>
//             </div>
//     );
// };


// const userId = localStorage.getItem('user_id');
// const role = localStorage.getItem('role');

const ShowOrderFinished = ({staticContext, ...props}) => {
    const classes = useStyles();
    return(
    <Show title={<Title />} {...props} actions={<ShowActions />}>
            
        {/* <SimpleShowLayout> */}
        <TabbedShowLayout redirect="list">
            <Tab label="Infos">
                <div/>
                <TextField source="order_number" label="Nº de commande" className={classes.inlineBlock}/>
                <OrderTypeField source="order_type" label= "Type de commande" className={classes.inlineBlock}/>
                <ReferenceField label="Type de véhicule" source="vehicle_type_id" reference="vehicle_type" className={classes.inlineBlock} link="">
                <TextField source="name" />
                </ReferenceField>
                {/* <OrderActivityTypeField source="order_type" label= "Type d'activité" className={classes.inlineBlock}/> */}
                <div/>
                <FormattedDateField source="create_time" format="format_date_time" label="Date/heure"/>
                <OrderStatusField label = "État" className={classes.colorPrimary}/>
                
                {/* <AnimalField/> */}

                {/* <TextField source="c_last_name" label="Nom du client" className={classes.inlineBlock}/>
                <TextField source="c_first_name" label="Prénom du client" className={classes.inlineBlock}/>
                <div/>
                <TextField source="d_last_name" label="Nom du chauffeur" className={classes.inlineBlock}/>
                <TextField source="d_first_name" label="Prénom du chauffeur" className={classes.inlineBlock}/> */}

                <div/>
                <TextField source="from_address" label = "Adresse de départ"/>
                <TextField source="to_address" label = "Adresse d'arrivée"/>
                <FormattedDateField source="pick_time" format="format_date_time" label="Prise en charge demandée"/>
                <FullNameClientField label="Client" className={classes.inlineBlock}/>
                <FullNameDriverField label="Chauffeur" className={classes.inlineBlock}/>
                <TextField source="comment_on_driver" label="Avis du client"/>
                <br/>
                <Divider/>
                
                {/* <TextField source="original_total" label="Montant sans frais d'attente(XPF)"/> */}
                <Typography style={{ fontWeight: 600, fontSize:15 }}>Montants</Typography>
                {/* <TextField source="tips_amount" label="Pourboire au chauffeur(XPF)" className={classes.inlineBlock}/> */}
                <TextField source="cancel_amount" label="Frais d'annulation(XPF)" className={classes.inlineBlock}/>
                
                <div/>
                <TextField source="commission_amount" label="Commission(XPF)" className={classes.inlineBlock}/>
                <TextField source="driver_amount" label="Commission chauffeur(XPF)" className={classes.inlineBlock}/>
                
                
                {/* <TextField source="pt_name" label="Mode de paiement"/> */}

                <div/>
                <TextField source="total" label="Total(XPF)" className={classes.inlineBlock}/>
                {/* <TextField source="payment_id" label="ID Transaction"/> */}
                <br/>
                <Divider/>
                
                {/* <Typography variant="body2" color="primary">Déroulement</Typography> */}
                <Typography style={{ fontWeight: 600, fontSize:15 }}>Déroulement</Typography>
                
                {/* <TextField source="wait_min" label="Temps d'attente(mn)"/> */}
                {/* <FormattedDateField source="start_time" format="format_date_time" label="Heure de début"/> */}
                
                <FormattedDateField source="at_pick_time" format="format_date_time" label="Sur place" className={classes.inlineBlock}/>
                <FormattedDateField source="start_time" format="format_date_time" label="Début" className={classes.inlineBlock}/>
                <FormattedDateField source="end_time" format="format_date_time" label="Arrivée" className={classes.inlineBlock}/>
                <div/>
                {/* <FormattedDateField source="end_time" format="format_date_time" label="Heure d'arrivée"/> */}
                <FormattedDateField source="cancel_time" format="format_date_time" label="Annulée"/>
                
            </Tab>

            {/* <Tab label="Avis">  */}
                {/* <TextField source="comment_on_client" label="Commentaire au client" className={classes.inlineBlock}/> */}
                {/* <RatingClientField label="Notation au client"/> */}

                {/* <br/>
                <TextField source="comment_on_driver" label="Commentaire au chauffeur" className={classes.inlineBlock}/> */}
                {/* <RatingDriverField label="Notation au chauffeur"/> */}
                {/* <br/>
                </Tab> */}
                {/* <Tab label="Problèmes"> */}
                {/* <TextField source="comment_on_client" label="Commentaire au client" className={classes.inlineBlock}/>
                <RatingClientField label="Notation au client"/>

                <br/> */}
                {/* <TextField source="claim_on_driver" label="Problème signalé par client"/>
                <TextField source="claim_on_client" label="Problème signalé par chauffeur"/>
                <br/>
                </Tab>*/}
            <Tab label="Factures">
            <ReferenceManyField reference="invoice" target="order_id" addLabel={false}>
                    
                    <Datagrid>
                    
                        <TextField label="Nom" source="invoice_name"/>
                        <TextField label="Numéro de facture" source="invoice_number"/>
                        {/* <TextField label="Date de facture" source="invoice_date"/> */}
                        <FormattedDateField source="invoice_date" format="format_date" label="Date de facture"/>
                        <DownloadFileButton/>
                    </Datagrid>
                </ReferenceManyField>
            
            </Tab>
             </TabbedShowLayout>

            {/* <div/>
            <Divider/>
            <br/>
            <Typography variant="body2" color="primary">Factures</Typography>
            <div/>
                

        </SimpleShowLayout> */}
    </Show>
)};


export default ShowOrderFinished;