import React, {Fragment} from 'react';
import { ReferenceField, List, ReferenceInput, Datagrid, TextField, DateField, SelectInput, EditButton, Filter, TextInput, useTranslate } from 'react-admin';
import BatchDeleteButton from '../../control/button/batch-delete-button';
import {locationDict, locationTypes} from '../../util/variant'
import {OrderTypeField, OrderTypeFilter, ActivityTypeField} from '../../control/field/index'
import { orderTypes } from '../../util/constant';

const BulkActionButtons = props => (
    <Fragment>
        <BatchDeleteButton label="Supprimer" {...props} />
    </Fragment>
);
const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.price.list')}</span>;
};

// const TypeTextField = ({ source, record = {}, label}) => {
//     const locationType = locationDict[record.type];
//     return <span class="MuiTypography-root MuiTypography-body2">{typeof(locationType) !== 'undefined' ? locationType.name : record.type}</span>;
// }
    
// TypeTextField.defaultProps = {
//     addLabel: true,
// };

const ListFilter = ({ permissions, ...props })  => (
    <Filter {...props}>
        
        <SelectInput label="Type de commande" source="order_type" alwaysOn choices={orderTypes}
            allowEmpty emptyText="Tous les types" emptyValue='-1' initialValue='-1'
        />
        
         <ReferenceInput label="Type de véhicule" source="vehicle_type_id"
            reference="vehicle_type" perPage={20} sort={{field: 'name', order: 'ASC'}}
            alwaysOn>
            <SelectInput
                optionText='name' emptyText="Tous"
              
            />
        </ReferenceInput>
    </Filter>
);

const ListPrice = ({permissions, ...props}) => (
    <List title={<Title/>} {...props} 
        filters = {<ListFilter permissions={permissions} />} 
        bulkActionButtons={<BulkActionButtons />} exporter={false}>
        <Datagrid rowClick="edit">
            <TextField source="vehicle_type_name" label = "Type de véhicule"/>
            {/* <ActivityTypeField label="Type d'activité"/> */}
            <OrderTypeField source="order_type" label="Type de commande"/>
            <TextField source="from_km" label="À partir de(km)"/>
            <TextField source="to_km" label = "Moins que(km)"/>
            <TextField source="per_km" label="XPF/km"/>
            <TextField source="markup" label="Extra(XPF)"/>
            <TextField source="min" label="Minimum(XPF)"/>
        </Datagrid>
    </List>
);

export default ListPrice;

