
import React, {Link, Component} from 'react';
import { Pagination, ReferenceField, DateField, ReferenceManyField, Datagrid,TextField, Show,useTranslate, TabbedShowLayout, Tab, ImageField } from 'react-admin';
import { EditButton, ListButton } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { Form } from 'react-final-form'
import Divider from '@material-ui/core/Divider';
import {DocApproveButton, WalletResetButton, DocRejectButton, DeleteButton, AvatarButton} from '../../control/button/index';

import ImageViewer from '../../control/viewer/image-viewer';

import CardActions from '@material-ui/core/CardActions';

import {DataStatusField, OrderTypeField, DocStatusField, OrderStatusField, FormattedDateField, ActivityTypeField, AvatarField, FullNameClientField,
    ValidateField, ValidityDateField, DriverCompanyTypeField, FileCountField, ClientDataField, PhotoField, DocNameField, DocStatusReasonField} from '../../control/field/index'
import {VehicleLockButton, VehicleUnlockButton, VehicleDateEditButton, 
    VehicleApproveButton, VehicleRejectButton, 
    DocDateEditButton, ValidateButton, CustomerLockButton, CustomerUnlockButton} from '../../control/button/index'

import {Status, DataStatus, FALSE, TRUE, USER_TYPE_COMPANY_AND_DRIVER} from '../../util/constant'
import {BACKEND_FILE_URL} from '../../util/variant'

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
    colorLabel: { paddingLeft: 50, color: '#c30040',},
    
    colorPrimary: {color:'#c30040',},
    
    noColorLabel: {paddingLeft: 50,  
    },

    colorValue: {color: '#c30040',paddingLeft:5},

    supplementText: {color: '#c30040',},

    noHeader:{display:'none',
    // height:'1px'
    },

    image:{width:'200px',height:'200px', borderRadius: '50%'},

    
});

const styles = {
    flex: { display: 'flex'},
    images:{display: 'flex', paddingLeft:'1em', marginLeft:"3em" },
    flexColumn: { display: 'flex', flexDirection: 'column', },
    leftCol: { flex: 1, marginRight: '1em' },
    rightCol: { flex: 1, marginLeft: '1em' },
    singleCol: { marginTop: '2em', marginBottom: '2em' },
    map:{ flex: 1, marginTop: '1em', marginBottom: '1em' },
    button:{ flex: 1, marginTop: '1em'},
};

const cardActionStyle = {
    // zIndex: 2,
    // display: 'inline-block',
    // float: 'right',
    zIndex: 2,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    padding: 0,
};





const ShowActions = ({ basePath, data, resource }) => (
    <CardActions style={cardActionStyle}>
        
        {/* Add your custom actions */}
        {/* <Button color="primary" onClick={changePassword}>CHANGER LE MOT DE PASSE</Button> */}
        {/* <Link to="/change-password">Changer le mot de passe</Link> */}
        {typeof data != 'undefined' &&  typeof data.validated != 'undefined' && data.validated == FALSE &&
            <ValidateButton basePath={basePath} record={data} resource={resource}/>
        }
        {typeof data != 'undefined' &&  typeof data.locked != 'undefined' && data.locked === Status.UNLOCKED &&
            <CustomerLockButton basePath={basePath} record={data} resource={resource}/>
        }
        {typeof data != 'undefined' &&  typeof data.locked != 'undefined' && data.locked === Status.LOCKED &&
            <CustomerUnlockButton basePath={basePath} record={data} resource={resource}/>
        }
        
        <DeleteButton resource="driver" basePath={basePath} record={data} content="Êtes-vous sûr de vouloir supprimer ce chauffeur? Toutes les donnnées personnelles de ce chauffeur seront supprimées"/>
        {/* <EditButton basePath={basePath} record={data} /> */}
        <ListButton basePath={basePath}/>
        {/* <BackButton/> */}

    </CardActions>
);

const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.driver.show')}: {record ? `${record.email}` : ''}</span>;
};

const AvatarChangeButton = ({ record, basePath, resource}) => {
    return <AvatarButton basePath={basePath} record={record} resource={resource}/>;
}

const DocContentPanel = ({ id, record, resource }) => {
    const classes = useStyles();
    console.log("File record " + JSON.stringify(record));
    var files = record.files;
    files.forEach(function(file, index){
        if(!file.file_url.startsWith("http://") && !file.file_url.startsWith("https://")){
            file.file_url = BACKEND_FILE_URL + file.file_url;
            files[index] = file;
        }
    }
    , files);

    if(files.length === 0)
        return <div>
        <ValidityDateField record={record}/>
        <Typography variant="body2" className={classes.colorLabel}>Aucun fichier</Typography>
        </div>;
    
    return(
        <div style={styles.flexColumn}>
            <Typography variant="body2" className={classes.noColorLabel}>Fichier(s):</Typography>
            <br/>
            <div style={styles.images}>        
                {files.map(file => {
                    return <ImageViewer files={files} fileUrl={file.file_url} title="" width='300' height='300' className={classes.noColorLabel}/>
                })}
            </div>
            <ValidityDateField record={record}/>
            <div className={classes.noColorLabel}><span>Actions: </span>
                {/* {record.date !== '' && <ĐocDateEditButton record={record}/>} */}
                <DocRejectButton record={record}/><DocApproveButton record={record}/></div>

        </div>
    )
};

const VehicleDateField = ({ record = {}, label}) => {
    const classes = useStyles();
    return record.circulation_date !== "" ? <div><br/>
            <Typography variant="body2" className={classes.noColorLabel}>
            Date de la 1e mise en circulation: {record.date !== "" ? <DateField className={classes.colorValue} source="circulation_date" record={record} label=""  locales="fr-FR"/> : '--'}
            <VehicleDateEditButton record={record}/>
        </Typography><br/>
    </div> : '';
}
// className={classes.noColorLabel}
const BooleanField = ({ record = {}, source,  label}) => {
    const classes = useStyles();
    const value = record[source] === "1" ? "Oui" : "Non";
    return <div>
            <Typography variant="body2">
            {value}XPF
        </Typography></div>;
    
}

BooleanField.defaultProps = {
    addLabel: true,
};

// const WalletBalanceField = ({ record = {wallet:{balance: 0}}}) => {
//     const classes = useStyles();
//     const value = record.wallet.balance;
//     return <div>
//             <Typography variant="body1" style={{ fontWeight: 600, fontSize:15 }}>
//             PORTEFEUILLE : {value}XPF
//         </Typography><WalletResetButton record={record} content="Voulez-vous remettre le solde du wallet du chauffeur à 0 ?"/></div>;
    
// }

// WalletBalanceField.defaultProps = {
//     addLabel: false,
// };

// const WALLET_ACTION_END_CHARGE = 1;
// const WALLET_ACTION_CANCEL_CHARGE = 2;
// const WALLET_ACTION_CREATE_CHARGE = 3;
// const WALLET_ACTION_TIPS = 4;
// const WALLET_ACTION_RESET = 5;

// const WalletActionField = ({ record = {action: 0}}) => {
//     const classes = useStyles();
//     let text = "";
//     if(record.action == WALLET_ACTION_END_CHARGE) text = "Frais de la course";
//     else
//     if(record.action == WALLET_ACTION_CANCEL_CHARGE) text = "Frais d'annulation";
//     else
//     if(record.action == WALLET_ACTION_CREATE_CHARGE) text = "Frais de création de la course";
//     else
//     if(record.action == WALLET_ACTION_TIPS) text = "Pourboire";
//     else
//     if(record.action == WALLET_ACTION_RESET) text = "Remise à 0";
//     return <div>
//             <Typography variant="body2">
//             {text}
//         </Typography></div>;
    
// }

// WalletActionField.defaultProps = {
//     addLabel: false,
// };


// const AvatarChangeButton =  ({ record = {}, source,  label}) => {
//     return <AvatarButton user={record}/>;
// }

// AvatarChangeButton.defaultProps = {
//     addLabel: false,
// };


const VehicleContentPanel = ({ id, record, resource }) => {
    const classes = useStyles();
    console.log("Vehicle record " + JSON.stringify(record));
    // var transportInsuranceCertUrl = record.transport_insurance_cert_url;
    // var hasTransportInsurance = transportInsuranceCertUrl != null && transportInsuranceCertUrl != "";

    // if(transportInsuranceCertUrl !== '' && !transportInsuranceCertUrl.startsWith("http://") && !transportInsuranceCertUrl.startsWith("https://"))
    // transportInsuranceCertUrl = BACKEND_FILE_URL + transportInsuranceCertUrl;
    var greenCardCertUrl = record.green_card_url;
        if(greenCardCertUrl !== '' && !greenCardCertUrl.startsWith("http://") && !greenCardCertUrl.startsWith("https://"))
        greenCardCertUrl = BACKEND_FILE_URL + greenCardCertUrl;
    var grayCardUrl = record.gray_card_url;
    if(grayCardUrl !== '' && !grayCardUrl.startsWith("http://") && !grayCardUrl.startsWith("https://"))
        grayCardUrl = BACKEND_FILE_URL + grayCardUrl;
    
    var frontImageUrl = record.front_image_url;
    if(frontImageUrl !== '' && !frontImageUrl.startsWith("http://") && !frontImageUrl.startsWith("https://"))
        frontImageUrl = BACKEND_FILE_URL + frontImageUrl;

    // var vvDdppUrl = record.vv_ddpp_url;
    // var hasVvDdpp = vvDdppUrl != null && vvDdppUrl != "";
    // if(vvDdppUrl !== '' && !vvDdppUrl.startsWith("http://") && !vvDdppUrl.startsWith("https://"))
    // vvDdppUrl = BACKEND_FILE_URL + vvDdppUrl;

    var files = [];
    files[0] = {file_url: frontImageUrl, name: 'Photo extérieure du véhicule (face avant et plaque visible)'};
    // files[1] = {file_url: rearImageUrl, name: 'Photo intérieur du véhicule'};
    files[1] = {file_url: grayCardUrl, name: 'Carte grise verso ou contrat de location - recto'};
    files[2] = {file_url: greenCardCertUrl, name: 'Assurance à titre onéreux'};
    

    // if(hasTransportInsurance)
    //     files[3] = {file_url: transportInsuranceCertUrl, name: "Attestation d'assurance auto ou la responsabilité civile de circulation"};
    // else
    // if(hasVvDdpp)
    //     files[3] = {file_url: vvDdppUrl, name: "Véhicule validé par la DDPP"};
    
    return(
       
            <div style={styles.flexColumn}>
                <div style={styles.images}>        
                    {files.map(file => {
                        return <ImageViewer files={files} fileUrl={file.file_url} title={file.name} width='300' height='300'/>
                    })}
                </div>
                {/* <VehicleDateField record={record}/> */}
                {/* <div className={classes.noColorLabel}><span>Actions: </span>
                {console.log("Vehicle is locked = " + record.is_locked)}
                {record.is_locked === Status.UNLOCKED && <VehicleLockButton record={record}/>}
                {record.is_locked === Status.LOCKED && <VehicleUnlockButton record={record}/>}
                {record.status == DataStatus.NEW && 
                <VehicleApproveButton record={record}/>}
                {record.status == DataStatus.NEW && 
                <VehicleRejectButton record={record}/>}
                </div> */}
            </div>
    );
};

const ContentTextField = ({ source, record = {}, label}) => {
    if(typeof(record) !== 'undefined'){
        var content = record['content'];
        if(typeof(content) !== 'undefined' && content !== null)
            content = content.replace(/(<([^>]+)>)/ig,"");
        return <span class="MuiTypography-root MuiTypography-body2">{content}</span>
    }
    return <span></span>
}
    
ContentTextField.defaultProps = {
    addLabel: true,
};


// const userId = localStorage.getItem('user_id');
// const role = localStorage.getItem('role');
const orderRowClick = (id, basePath, record) => '#/order/' + record.id;

// import { withStyles } from "material-ui/styles";

const ClientField = ({ record = {}, }) =>{
if(typeof(record) === 'undefined' ||record === null || typeof(record.client) === 'undefined'  || record.client === null || record.client === '')
return <div/>;
var client = JSON.parse(record.client);
console.log("Client " + client['first_name'] + " " + client['last_name']);
return (<div><span>
        {client['first_name']}&nbsp;{client['last_name']}
        </span>
    </div>
);
}
ClientField.defaultProps = {
    addLabel: true,
};



const ShowDriver = ({staticContext, ...props}) => {
    const translate = useTranslate();
    const classes = useStyles();
  
    return(
        
    <Show component="div" title={<Title />} actions={<ShowActions/>} {...props}>
            <TabbedShowLayout redirect="list">
            <Tab label="Profil">
                <AvatarField source="photo_url" label="" size='200'/>
                {/* <PhotoField source="photo_url" label=""/> */}
                {/* <div style={{"width":"60px" , "height":"60px"}}> */}
                {/* <ImageField source="photo_url" label="" className="avatar"/> */}
                {/* </div> */}
                {/* <ImageField source="photo_url" label="" style={{"width":"60px" , "height":"60px", "border-radius": "50%"}}/> */}
                {/* <AvatarChangeButton/> */}
                {/* <TextField source="user_code" label="Identifiant unique"/> */}
                {/* <TextField source="id" label="ID"/> */}
                {/* <GenderField label={translate('civility')}/> */}
                {/* <ReferenceField label="Nom de société" source="company_id" reference="driver_company" link="show">
                    <TextField source="company_name" />
                </ReferenceField> */}
                <div/>
                <TextField source="last_name" label="Nom" className={classes.inlineBlock}/>
                <TextField source="first_name" label="Prénom " className={classes.inlineBlock}/>
                <div/>
                <div/>
                <TextField source="mobile" label = "Mobile" className={classes.inlineBlock}/>
                <TextField source="email" label = "Email" className={classes.inlineBlock}/>

                {/* <ActivityTypeField source="activity_type" label = "Type d'activité"/>
                <ValidateField source="animal_accepted" label="Animal accepté"/> */}
                {/* <TextField source="wallet.balance" label="Portefeuille(XPF)" className={classes.inlineBlock}/> */}
                {/* <br/> */}
                {/* <FormattedDateField source="birth_date" label="Date de naissance"  locales="fr-FR"/> */}
                <FormattedDateField source="create_time" label="Date d'inscription"  locales="fr-FR"/>
                {/* <br/>
                <Divider/> */}
                {/* <br/> */}
                {/* <Typography variant="body2">État</Typography> */}
                <ValidateField source="is_admin" label='Chauffeur admin'/>
                <TextField source="activation_code" label="Code d'activation"/>
                <ValidateField source="activated" label='Activé' className={classes.inlineBlock}/>
                <ValidateField source="validated" label='Validé' className={classes.inlineBlock}/>
                <ValidateField source="locked" label='Verrouillé' className={classes.inlineBlock}/>
            </Tab>

            <Tab label="Infos de société">
                {/* <AvatarField source="photo_url" label="" size='80'/> */}
                {/* <PhotoField source="photo_url" label=""/>
                <TextField source="id" label="ID"/> */}
                {/* <GenderField label={translate('civility')}/> */}
                {/* <ReferenceField label="Nom de société" source="company_id" reference="driver_company" link="show">
                    <TextField source="company_name" />
                </ReferenceField>
                <div/> */}
                <Typography style={{ fontWeight: 600, textDecorationLine: 'underline', fontSize:15 }}>Société</Typography>
                
                <DriverCompanyTypeField label="Type de société"/>
                <TextField source="company_name" label="Nom de société"/>
                <TextField source="company_address" label="Adresse du siège social "/>
                <TextField source="postal_code" label="Code postal"  className={classes.inlineBlock}/>
                <TextField source="city" label="Ville"  className={classes.inlineBlock}/>
                
                {/* <TextField source="phone" label = "Téléphone"/> */}
                <TextField source="iban" label = "IBAN"/>
                {/* <TextField source="siren_number" label = "Nº SRIEN"/> */}
                {/* <TextField source="vat_no" label = "Nº de TVA intercommunautaire"/> */}
                <br/>
                {/* <Typography style={{ fontWeight: 600, textDecorationLine: 'underline', fontSize:15 }}>Représentant légal</Typography>
                <TextField source="manager.last_name" label = "Nom" className={classes.inlineBlock}/>
                <TextField source="manager.first_name" label = "Prénom" className={classes.inlineBlock}/>
                <FormattedDateField source="manager.birth_date" label="Date de naissance"  locales="fr-FR"/>
                <TextField source="manager.address" label = "Adresse"/>
                <TextField source="manager.country_code" label = "Pays"/> */}

                {/* <TextField source="email" label = "Email" className={classes.inlineBlock}/> */}
                {/* <br/> */}
                {/* <FormattedDateField source="birth_date" label="Date de naissance"  locales="fr-FR"/> */}
                {/* <FormattedDateField source="create_time" label="Date d'inscription"  locales="fr-FR"/> */}
                {/* <br/>
                <Divider/> */}
                {/* <br/> */}
                {/* <Typography variant="body2">État</Typography> */}
                {/* <ValidateField source="activated" label='Activé' className={classes.inlineBlock}/>
                <ValidateField source="validated" label='Validé' className={classes.inlineBlock}/>
                <ValidateField source="locked" label='Verrouillé' className={classes.inlineBlock}/> */}
            </Tab>

            {/* <Tab label="Paramètres">
                <BooleanField source="settings.3rd_payment" label="Tiers payant"/>
                <DriverSettingsButton/>
            </Tab> */}
            
            <Tab label="Documents">
                <ReferenceManyField reference="doc" target="user_id" addLabel={false}>
                    <Datagrid expand={<DocContentPanel/>} expandHeader={<div/>}>
                        {/* <DocNameField label="Liste de documents d'activité du chauffeur"/> */}
                        <DocNameField label="Nom du document"/>
                        <FileCountField label="Fichiers"/>
                        <DocStatusField source="status" label="État"/>
                        <DocStatusReasonField label="Motif de refus"/>
                        {/* <RejectDocButtonX label="Actions"/>
                        <ApproveDocButton label="Actions"/> */}
                        {/* <OpenFileButton/> */}
                        
                    </Datagrid>
                </ReferenceManyField>
                {/* <FileField source="files" src='url' title='title' label="Fichiers" target="_blank"/> */}
                {/* <FileViewer source="files" src='url' /> */}
            </Tab>
            <Tab label="Véhicules">
                <ReferenceManyField pagination={<Pagination />} reference="vehicle" target="user_id" addLabel={false}>
                    <Datagrid expand={<VehicleContentPanel/>}>
                        <TextField source="plate_number" label="Immatriculation" className={classes.colorValue} />
                        <TextField source="brand_name" label="Marque"/>
                        <TextField source="model_name" label="Modèle"/>
                        <TextField source="seats" label="Passagers max"/>
                        {/* <TextField source="vehicle_type_name" label="Type"/> */}
                        {/* <TextField source="color" label="Couleur"/> */}
                        {/* <FormattedDateField source="circulation_date" label="Date de la 1e mise en circulation"  locales="fr-FR"/> */}
                        <FormattedDateField source="control_date" label="Date du contrôle DITTT"  locales="fr-FR"/>
                        
                        <ValidateField source="is_default" label="Par défaut"/>
                        <DataStatusField source="status" label="État"/>
                        <ValidateField source="is_locked" label="Vérrouillé"/>
                        
                        {/* <VehicleDateEditButton/> */}
                    </Datagrid>
                </ReferenceManyField>
                
            </Tab>
            <Tab label="Commandes">
                <ReferenceManyField pagination={<Pagination />} reference="order_driver" target="driver_id" addLabel={false}>
                        <Datagrid rowClick="show">
                        {/* <TextField source="create_time" label="Date\heure"/> */}
                        <FormattedDateField source="create_time" format="format_date_time" label="Date\heure"  locales="fr-FR"/>
                        <TextField source="order_number" label="Nº de commande"/>
                        <OrderTypeField source="order_type" label= "Type de commande" className={classes.inlineBlock}/>
                        <ReferenceField label="Type de véhicule" source="vehicle_type_id" reference="vehicle_type" className={classes.inlineBlock} link="">
                        <TextField source="name" />
                        </ReferenceField>
                        {/* <ClientDataField source="last_name" label="Nom du client"/>
                        <ClientDataField source="first_name" label="Prénom du client"/> */}
                        <TextField source="from_address" label="Adresse de départ"/>
                        <TextField source="to_address" label="Adresse d'arrivée"/>
                        <TextField source="driver_amount" label="Prix(XPF)"/>
                        <ClientField label="Client"/>
                        {/* <ReferenceField label="Client" source="client_id" reference="client">
                            <TextField source="full_name" />
                        </ReferenceField> */}
                        <OrderStatusField source="status" label = "État"/>
                        {/* <TextField source="rating_on_driver" label="Note attribué par le client"/> */}
                        </Datagrid>
                    </ReferenceManyField>
            </Tab>
        </TabbedShowLayout>

       
    </Show>
    );
};


export default ShowDriver;

   {/* <Tab label="Informations de la société">
                <UserTypeField label="Type d'entreprise"/>
                <TextField source="company_name" label = "Nom"/>
                <TextField source="company_address" label = "Adresse"/>
                <div/>
                <TextField source="postal_code" label="Code postal" className={classes.inlineBlock}/>
                <TextField source="city" label="Ville" className={classes.inlineBlock}/>                
            </Tab> */}