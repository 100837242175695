import jsonDataProvider from './json-data-provider';
import {BACKEND_UMS_URL, BACKEND_OMS_URL} from '../util/variant';
import {httpClient} from '../util/function'

const superDataProvider = (type, resource, params) => {
    // if(resource === 'order' || resource === 'order_running'){
    //     return jsonDataProvider(BACKEND_OMS_URL, httpClient)(type, resource, params);
    // }
    return jsonDataProvider(BACKEND_UMS_URL, httpClient)(type, resource, params);
};

export default superDataProvider;
