import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { showNotification, Confirm, crudGetOne, Button, Loading } from 'react-admin';
import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import RemoveRedEye from '@material-ui/icons/RemoveRedEye';
// import {DataStatus, BACKEND_URL} from '../../util/constant'
import {PdfViewer} from '../viewer/index'

// import axios from 'axios';

class PdfViewButton extends Component {
    state = {
        error: false,
        showDialog: false,
        fileUrl: "",
    };

    // _handleClick = () => {
    //     // window.location.href = '/#/foo';
    //     console.log('Props of preview button');
    //     console.log(this.props);
    //     const selectedIds = this.props.selectedIds;
    //     var params = '';
    //     if(typeof selectedIds !== 'undefined'){
    //         selectedIds.forEach(function(selectedId) {
    //             params += selectedId + ',';
    //           });
    //     }
    //     window.open('/#/preview/' + params);
    // }

    handleClick = () => {
        this.setState({ showDialog: true });
    };

    // fetchData = (selectedIds) => {
    //     const token = localStorage.getItem('token');
    //     const url = BACKEND_URL + '/list-doc/' + selectedIds;
    //     console.log('Get preview url: ' + url);
    //     const request = new Request(url, {
    //         method: 'GET',
    //         headers: new Headers({ 'Content-Type': 'application/json', 
    //                                 'Authorization': `Bearer ${token}` }),
    //     })
    //     fetch(request)
    //         .then(response => {
    //             if (response.status < 200 || response.status >= 300) {
    //                 throw new Error(response.statusText);
    //             }

    //             response.json().then(json => {
    //                 console.log("Get preview response json: " + JSON.stringify(json));
    //                 var fileArray = [];
    //                 var i = 0;
    //                 json.forEach(function(doc){
    //                    const files = doc.files;
    //                    if(files !== null && typeof files !== 'undefined'){
    //                         files.forEach(function(file){
    //                             if (typeof file.url !== 'undefined'){
    //                                 if(!file.url.startsWith("http://") && !file.url.startsWith("https://"))
    //                                     file.url = BACKEND_URL + "/" + file.url;
    //                                 if(!file.thumbnail.startsWith("http://") && !file.thumbnail.startsWith("https://"))
    //                                     file.thumbnail = BACKEND_URL + "/" + file.thumbnail;
    //                                 fileArray[i] = file;
    //                             }
    //                         i ++;
    //                     });
    //                    }
                       
    //                 });

    //                 this.setState({files: fileArray});
    //                 console.log("#Preview files:");
    //                 console.log(this.state.files);

    //                 this.setState({ showDialog: true });
    //               });
              
    //         });
    // };


    handleCloseClick = () => {
        this.setState({ showDialog: false });
    };

    static contextTypes = {
      router: () => true, // replace with PropTypes.object if you use them
    }

    render() {
        return (
            <Fragment>
                <Button label="" onClick={this.handleClick} disabled={false}> 
                    <RemoveRedEye/>
                </Button>
                { this.state.isLoading && 
                    <Loading loadingPrimary="" loadingSecondary="" />
                    }
               
                <Dialog
                    fullWidth={true}
                    maxWidth="1000xs"
                    open={this.state.showDialog}
                    onClose={this.handleCloseClick}
                    aria-label="Facture"
                >
                    <DialogTitle></DialogTitle>
                   
                    <DialogContent>
                    <div style={{width:'100%', height:'100%', border:'1px solid white'}}>
                            <PdfViewer file={this.props.file}/>
                    </div>
                    </DialogContent>
                    <DialogActions>
                        <Button label="Fermer" onClick={this.handleCloseClick}>
                            <IconCancel />
                        </Button>
                    </DialogActions>
                </Dialog>
            </Fragment>
        );
    }
    
    
}

export default PdfViewButton;

// HandleButton.propTypes = {
//     push: PropTypes.func,
//     record: PropTypes.object,
//     showNotification: PropTypes.func,
// };

// export default connect(null, {
//     showNotification,
//     push,
// })(UnhandleButton);