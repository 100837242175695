
import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Confirm, crudDelete,  } from 'react-admin';
import ActionDelete from '@material-ui/icons/Delete';

class DeleteButton extends Component {
    state = {
        isOpen: false,
    }
    
    handleClick = () => {
        this.setState({ isOpen: true });
    }

    handleDialogClose = () => {
        this.setState({ isOpen: false });
    };

    handleConfirm = () => {
        console.log("Delete button props " + JSON.stringify(this.props));
        const { basePath, crudDelete, resource, record, redirect } = this.props;
        // crudDelete(resource, record.id, basePath);
        crudDelete(resource, record.id, record, basePath, redirect);
        this.setState({ isOpen: true });
        
    };

    render() {
        return (
            <Fragment>
                <Button label="ra.action.delete" onClick={this.handleClick}>
                    <ActionDelete />
                </Button>
                <Confirm
                    isOpen={this.state.isOpen}
                    title="Suppression"
                    content={this.props.content}
                    onConfirm={this.handleConfirm}
                    onClose={this.handleDialogClose}
                />
            </Fragment>
        );
    }
}

DeleteButton.propTypes = {
    label: PropTypes.string,
    redirect: PropTypes.string,
};

export default connect(undefined, { crudDelete })(DeleteButton);